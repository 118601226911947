// vuex store for portal application
// Part of the SPARKL educational activity system, Copyright 2019 by Pepper Williams

import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		// this imports the version number from package.json, so we can show the version number in the ui with $state.store.app_version; also need something in vue.config.js (PACKAGE_VERSION)
		// run the following to update the third number; use 'minor' to update the second number and 'major' to update the first number
		// npm --no-git-tag-version version patch; npm run build; npm run serve
		app_version: process.env.PACKAGE_VERSION || '0.0.0',

		// set site_config values to what's needed for GA Inspire
		site_config: {
			// if this is set we'll show a maintenance message and force to the signin screen unless the user uses the magic password
			'down_until': '',

			'app_name': 'Inspire',
			'agency_name': 'Georgia Department of Education',
			// 'agency_name_css': 'top:-7px!important;line-height:35px!important;',
		
			// included in index.html as the browser window/tab title
			'index_title': 'Inspire',
		
			// for most instances, add to /src/logos/; GA inspire is special...
			'agency_logo': 'https://inspire.gadoe.org/src/logos/GaDOE Logo RGB 600px wide.png',
		
			// banner color and color of the "agency" string
			'banner_color': '#F4DDCB',
			'agency_color': '#CB6015',
			'app_color': '#FFFFFF',
			'agency_name_font_family': "'Port Lligat Slab', 'Roboto', 'Calibri', 'Helvetica Neue', 'Helvetica' , 'Arial', sans-serif",
			'agency_name_font_family_href': 'https://fonts.googleapis.com/css2?family=Port+Lligat+Slab&display=swap',
			'banner_user_name_color': '#222222',

			// vue primary/secondary colors (used, e.g., for buttons)
			'vue_primary_color': '#206166',
			'vue_secondary_color': '#ef6400',

			// color used in messages interface
			'vue_messages_color': '#cccccc',

			// spinner colors; if not provided, we'll use vue primary and secondary colors
			'spinner_color_1': '',
			'spinner_color_2': '',

			// background images
			// In most cases, add to /src/bgd-imgs; For GA inspire we will use the images in (/vue-cli/public)/bgd-imgs
			'site_bgd_images': [
				'/bgd-imgs/stock-1.1.jpg',
				'/bgd-imgs/stock-2.1.jpg',
				'/bgd-imgs/stock-3.jpg',
				'/bgd-imgs/stock-4.jpg',
				'/bgd-imgs/stock-5.jpg',
				'/bgd-imgs/stock-6.jpg',
				'/bgd-imgs/stock-7.jpg',
			],

			// css/text to adjust the way the agency logo and app title appears in the banner
			'agency_img_css': 'height:64px; top:4px; left:0; background-color:#f4ddcb; padding:4px 16px 8px 12px;',
			'banner_logo_css': 'font-weight:bold; left:120px; top:-3px; color: #e20177; background: -webkit-linear-gradient(#ef6400, #e20177); -webkit-background-clip: text; -webkit-text-fill-color: transparent;',
			'banner_logo_text': 'Inspire',
			// note that the below won't be used for oidc login
			'login_wrapper_css': '',
			'login_logo_img_css': '',
			'login_logo_text_css': 'font-weight:bold;',
			'login_logo_text': 'Inspire',

			// show a custom message on the login form
			'login_msg': '',
			
			// satchel name/origin
			'satchel_app_name': 'SuitCASE',
			'satchel_origin': 'https://case.georgiastandards.org',
		
			// sparkl name/origin
			'sparkl_app_name': 'Velocity', 
			'sparkl_origin': 'https://velocity.gadoe.org',

			// sparkl_embedder_origin -- used by sparkl when embedded to do some behavior switches
			'sparkl_embedder_origin': 'inspire',
		
			// Used in tooltip in CourseView: "Georgia Learning Standards for Science"
			// Additionally used in StandardsHome: "Georgia Learning Standards"
			'learning_standards_prefix': 'Georgia',
		
			// Used in tooltip in CourseView: "GaDOE Communities for Science"
			'communities_prefix': 'GaDOE',
		
			// Used in tooltip and as button text in CourseView:
			'pd_hub_prefix': 'Georgia Learns',
		
			'copyright_text': '© 2024 Georgia Department of Education',
		
			// login method
			'login_method': 'oidc',	// 'cureum', 'oidc'

			// allow for a login with username and password; note that this and the other login/password values below won't be used if login_methid is 'oidc'
			'allow_password_login': 'yes',

			// allow google login
			'allow_google_login': 'no',

			// allow msft login, show msft sign in button
			'allow_msft_login': 'no',

			// show the "create account" button on the signin page
			'show_create_account_on_login': 'yes',

			// show the "forgot password" button on the signin page
			'show_forgot_password_btn': 'no',

			// external file storage system to use: currently support 'none' or 'equella'
			'file_storage_system': 'equella',

			// set to 'yes' to enable user creation from the AdminUsers component
			'enable_new_user_creation_from_admin_users': 'no',

			// set to 'yes' to enable the "Simulate another role..." menu item for "normal" instances
			'enable_simulate_role_menu': 'yes',

			// set to 'yes' to enable the "Simulate another role..." menu item (used for demo "sandbox" instances)
			'enable_sandbox_simulate_role_menu': 'no',

			// whether or not to show signin/create account buttons in the banner
			'show_banner_signin_btn': 'no',
			'show_banner_create_account_btn': 'no',
			'banner_signin_btn_color': 'primary',

			// whether or not to show user system data on the home page
			'show_user_system_data_on_home': 'yes',

			// email domains that get "Import to district" menu options (if empty, no one will get these options)
			'import_to_district_domains': ['henry.k12.ga.us'],	// , 'commongoodlt.com'

			// whether or not to show 'communities' and 'pd hub' links (this will probably need to be made more general later, to support different buttons in different instances)
			'show_extra_subject_links_in_course_index': 'yes',

			// Added 11/20/2023 for Sparkl: tabs to include; default is 'all', or specify array with values from ['home', 'classes', 'resourcerepos', 'pd', 'mycollections', 'standards'] (see WelcomeView)
			'main_tabs_to_include': ['home', 'classes', 'resourcerepos', 'mycollections', 'standards'],

			// SF: added 9/13/2024 for ALEX.
			// Allows for optional custom title and icon in navigation tabs
			// Example implementation in config.php :
				// 'customized_tabs' => [
				// 	'classes' => [
				// 		'icon' => 'fa-sharp fa-solid fa-person-chalkboard',
				// 		'title' => 'Content Area'
				// 	],
				// 	'resourcerepos' => [
				// 		'icon' => 'fa-sharp fa-regular fa-book-bookmark',
				// 	],
				// ],
			'customized_tabs': [],

			// which content types to support; e.g. take out 'lesson' for cps
			'supported_content_types': ['lessons', 'activities', 'resources'],

			// if yes, user can't do anything without signing in -- we show the signin interface as soon as the app launches if no session is active
			'require_sign_in': 'no',

			// if this is yes, show the signin interface right away when a user first loads the site, but they can choose "guest mode" that allows them to view without signing in
			'show_signin_on_load': 'no',

			// if yes, we show *all* items and lessons when not signed in; if no, we limit to student-facing resources when not signed in
			'show_all_items_when_not_signed_in': 'yes',

			// whether or not to show the 'my' mycollections when not signed in -- might be, e.g., 'yes' for state implementations and 'no' for districts
			'show_mycollections_when_not_signed_in': 'yes',

			// whether or not to offer to show the 'Parent' role to all staff members
			'show_parent_role_to_all_staff': 'no',

			// noun to use for "Resource Repositories"
			'resourcerepos_noun': 'Resource Repositories',

			// label for the user's "my default content collection"
			'default_my_collection_label': 'My Content Sandbox',	// 'My Default Content Collection'

			// whether or not to include the "Include PDF resources" option when printing lessons
			'include_pdf_for_lesson_print': 'yes',

			// whether or not to enable the "AI enhanced" lesson option, and collection(s) where it should be enabled 
			'enable_llm_lesson_plans': 'no',
			'alba_collections': [],
			
			// whether or not to show the 'Submit Feedback' option in the resource tile menu
			'show_submit_feedback_option_for_resources': 'no',

			// whether or not to show the "Weekly Lesson Plans" option, currently appearing in the user menu
			'show_weekly_lesson_plans': 'no',

			// whether or not to show the "school leader tools" option, currently appearing in the user menu and in a button at the top of collections
			'show_school_leader_tools': 'no',

			// whether or not to show the 'mimic another user' option in the user menu for regular staff users; su users always see this option
			'staff_can_mimic_users': 'no',

			// ** whether or not to limit viewable agency-sanctioned courses by academic_year; defaults to 'no' (for GA Inspire); should be set to 'yes' for districts
			'limit_courses_to_academic_year': 'no',

			// default values for new "horizontal" collections
			'default_new_collection_scope_and_sequence_label': 'Curriculum Map',
			'default_new_collection_arrange_by_terms': 'no',
			'default_new_collection_specify_unit_numbers': 'yes',
			'default_new_collection_specify_unit_intervals': 'no',

			// course grades and subjects to include, and repository course codes to include (this is designed to be used when we're setting up a new installation and want to start with copies from another installation)
			// NOTE: this is more efficiently taken care of by the agency_sanctioned flag, but leave these here in case they come in handy later
			'grades_to_include': null,
			'subjects_to_include': null,
			'repositories_to_include': null,

			// grades
			// note that if the list of grades changes, might need to update code in CourseIndex
			'grades': [ 'PK', 'K', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12' ],
			// note that for a time we defined subjects here, but this has now been moved to the config file so it can be managed with an admin tool

			// if set, create a default set of folders when a new unit is created in a course collection
			'default_folders_for_course_units': null,
			// 'default_folders_for_course_units' => [
			// 	'leader_resources' => ['title' => 'Leader Resources', 'color' => 'brown'],
			// 	'course_guidance' => ['title' => 'Resources for Course Guidance', 'color' => 'cyan', 'cross_unit_key' => 'rcg'],
			// 	'unit_planning' => ['title' => 'Unit Planning Documents', 'color' => 'green'],
			// 	'tcc' => ['title' => 'tcc items', 'color' => 'indigo'],
			// 	'assessments' => ['title' => 'Assessments', 'color' => 'pink'],
			// 	'templates' => ['title' => 'Template Lesson Plans and Student Activities', 'color' => 'blue'],
			// 	'supplementals' => ['title' => 'Supplemental Resources', 'color' => 'blue-grey', 'children' => [
			// 		'student_ese' => ['title' => 'Additional Student ESE Resources', 'color' => 'teal'],
			// 		'student_advanced' => ['title' => 'Additional Student Advanced Learning Resources', 'color' => 'purple'],
			// 		'additional_student' => ['title' => 'Additional Student Resources from Unit Planning Guides', 'color' => ''],
			// 		'additional_teacher' => ['title' => 'Additional Teacher Resources from Unit Planning Guides', 'color' => ''],
			// 	],
			// ]],

			// whether or not to enable functionality for...
			'show_pd': 'no',						// pd collections
			'use_term_mode': 'no',					// block/normal term mode distinction for course
			'manage_assignments': 'no',				// Assignment Center functionality (the ability to create Directives within Units)
			'use_message_center': 'no',				// Message Center functionality
			'show_course_alt_cureum_btn': 'no',		// link to an alternative cureum site for courses (e.g. Inspire from HenryConnects)
			'show_safari_integration': 'no',		// links to Safari LOR searches
			'enable_admin_term_management': 'no',	// allows admin to manage when each term (e.g. quarter) starts/stops
			'enable_admin_lti_management': 'yes',	// allows admin to manage LTI tools for sparkl
			'show_update_sis_data_btn': 'no',		// for districts: allows user to force-update SIS data

			'include_assessment_resources': 'no',	//whether or not to include "assessment" type resources
			'include_block_or_traditional_setting_for_resources': 'no',	//whether or not to include the "block/traditional" setting for resources
			'include_family_setting_for_resources': 'no',	// whether or not to include the "Show to family members" setting for resources
			'show_restricted_resources_control': 'no',	// whether or not to include the "authorized teachers only" setting for resources
			'enable_flex_units': 'no',	// whether or not to show the option to create a flex unit

			// button text for course standards on the course page
			'course_standards_btn_text': 'Course Standards',

			// button text for "alt cureum" button on the course page, and domain for the alt cureum site
			'course_alt_cureum_btn_text': 'GaDOE Inspire',
			'alt_cureum_domain': 'inspire.gadoe.org',

			// ** whether or not we're using oneroster to connect with a SIS
			'use_sis_connection': 'no',


			// ** 'yes' if we want to record/display separate usage stats for staff/students/parents
			'record_stats_by_role': 'no',

			// These are the material UI colors from previous options
			'swatches': [
				['#C62828'],
				['#AD1457'],
				['#6A1B9A'],
				['#4527A0'],
				['#283593'],
				['#1565C0'],
				['#0277BD'],
				['#00818D'],
				['#00695C'],
				['#2E7D32'],
				['#558B2F'],
				['#9E9D24'],
				['#F9A825'],
				['#FF8F00'],
				['#EF6C00'],
				['#BF360C']
			],
		},

		// note that grades and subjects used to be loaded from a static file on the server that was passed in the initialize_app service; now it's in site_config
		grades: [],
		subjects: {},

		available_academic_years: [],
		default_academic_year: '',
		allow_academic_year_change_for_all_staff: false,

		// For PD Resources
		todo_user_group_divisions: {},
		todo_user_group_schools: {},
		todo_user_group_warning_issued: false,
		todo_report_group_showing: '',
		todo_report_group_data: {},

		login_msg: '',
		login_error: null,
		user_info: {},
		simulated_user_info: {},
		actual_user_info: {},

		home_page_content: {},

		resources: [],	// TODO: not used??
		all_resources: [],
		last_viewed_resource_id: '',

		n_collections_to_show_in_banner: 7,	// used in CollectionBase and Collection
		loaded_classes: false,
		sis_classes: [],
		added_my_courses: [],
		removed_my_courses: [],
		all_students: {},

		my_lessons: [],
		my_resources: [],
		my_ca_mappings: [],
		
		lesson_reports: [],
		user_records: [],	// used for holding looked-up user records for lesson_report collaborators/reviewers/owners

		// Assignment center stuff...
		my_activities: [],
		my_coteachers: [],
		my_coteaching_courses: [],
		my_activities_by_course: {},
		my_activity_results: {},

		// Message center stuff...
		messages: [],
		message_load_timestamp: 0,	// used to determine which messages to retrieve

		now_date_obj: new Date(),
		now_date_string: '',
		old_threshold_date_timestamp: 0,
		old_threshold_date_string: '',

		single_item: false,		// this will be get set to true if we're in a view where only a single item is showing -- /activity or /lesson
		single_item_mode: '',	// set in MyContentView2

		// these are to be deleted...
		my_copied_lessons: [],
		my_copied_activities: [],
		my_shadow_units: [],
		// froala_wrapper_components: {},	// used for implementing the "add resource" button in the froala editor

		collection_search_data: {},
		collection_last_search_results: [],	// this holds a list of resource/lesson/activity ids found in the last search of a collection

		// for SparklEmbed
		pm_event_listener_created: false,
		sparkl_embed_components: {},
		current_sparkl_embed_component: null,
		
		case_frameworks: {},	// used by the CASETree component to "cache" all loaded frameworks
		case_tree_showing: false,

		all_courses: [],
		all_courses_loaded: false,
		last_lp_list: null,	// used to know whether to go back to (the course index page or the my classes page) from a lp page
		course_update_trigger: 0,

		lesson_masters: [],
		default_lesson_master: null,

		google_client_id: '',

		froala_key: '',

		// checkout timestamp edit lock for collections
		// sent from server when aquiring  an edit lock
		lp_edit_locked: {},

		// this keeps track of the learning progression (course_code) that's currently showing; used for the socket and other things, i.e. CourseStandards
		lp_showing: '0',

		// FOR SPARKLSALT
		framework_records: [],
		framework_image_src_base: 'https://satchel.commongoodlt.com/src/filestore/images/',
		frameworks_loading: false,
		frameworks_loaded: false,
		case_tree_viewer_width: null,
		case_tree_viewer_height: null,
		association_type_labels: {
			exactMatchOf: 'Exact Match Of',
			replacedBy: 'Replaced By',
			hasSkillLevel: 'Skill Level',
			isRelatedTo: 'Related To',
			isPartOf: 'Part Of',
			precedes: 'Precedes',
			exemplar: 'Exemplar Of',
			isPeerOf: 'Peer Of',
		},

		default_beta_options: {
			// sparkl_student_activities: false,
			// resource_repositories: false,
			// new_course_index: false,
		},

		user_is_touching: false,	// initialized below

		// Term settings, loaded from config
		// and writeable by admins back to config via admin Term Management UI
		term_settings: {},

		// vapp.$store.commit('lst_set', ['beta_options', {sparkl_student_activities:true}])

		// "local_storage settings": set defaults here; lst_initialize is called on initialization; call lst_set to set new values, possibly in computed:
		// foo: {
		// 	get() { return this.$store.state.lst.foo },
		// 	set(val) { this.$store.commit('lst_set', ['foo', val]) }
		// },
		// @update:foo="(val)=>foo=val"
		lst: {
			simulated_date: '',
			simulated_role: '',
			simulated_user: '',

			// the following variables control which "classes" view is showing on the welcome page -- 'favorites' or 'lpindex'
			unsigned_index_view_flavor: '',				// this is for if you're not signed in, used for courses, repos, or my (though you don't actually have any my collections)
			course_index_view_flavor: 'favorites',		// this is for the courses tab
			my_index_view_flavor: 'lpindex',			// this is for the "my" tab
			repo_index_view_flavor: 'lpindex',			// this is for the repo/pd tab

			welcome_section_showing: '',	// which "tab" is showing on the home page
	
			login_email: '',
			beta_options: {},
			courseindex_opened_category: null,
			courseindex_opened_subcats: {},
			collections_opened_folders: {},
			unit_mode: 'resources',
			froala_image_size: '500',
			froala_paste_mode: 'normal',

			mathlive_advanced_options: false,
			mathlive_chosen_keyboard_index: 1,

			local_added_my_courses: [],
			sparkl_demo_email: '',	// see SparklEmbed
			collection_view_mode: 'tiles',	// other value is 'list'
			unit_descriptions_collapsed: false,
			collection_descriptions_collapsed: false,
			default_collection_sort_by: 'title',	// other value is 'created_at'
			default_collection_sort_by_created_at_order: 'desc',	// other value is 'asc'
			resource_search_sort_by: 'title',	// other value is 'created_at'
			resource_search_sort_by_created_at_order: 'desc',	// other value is 'asc'
			last_collections_viewed: [],
			selected_resource_search_types: [0,1,2],
			selected_resource_filter: 'none',
			resource_selector_new_or_search: 'new',
			child_email_showing: '',
			resource_editor_teacher_facing: true,
			resource_editor_additional_metadata_showing: false,
			resource_editor_case_framework_identifier: '',

			todo_report_show_empty_users: false,

			default_instance_unit: {}, // We store the most recently selected instance unit for each flex unit, keyed to flex unit lp_unit_id

			collection_assignments_or_messages_mode: 'unit_assignments',	// unit_assignments, course_assignments, messages
			my_content_items_mode: 'list',	// gantt
			my_content_assigned_to_filter: {},
			show_older_items: false,
			term_for_assignments: 0,
			assignment_chooser_sections: {},	// see SectionChooser.vue
			message_chooser_sections: {},		// see SectionChooser.vue
			bulk_activity_import_data: {},
			bulk_activity_settings: {},

			lp_format_showing: {},	// one value for each course code

			edit_llm_suggestion_prompts: false,
			use_llm_lesson_plans: false,
			gpt_model: 'gpt-4o-mini',
			gpt_temperature: '1',
			show_gpt_stats: false,
			alba_settings: {},
		},
		lst_prefix: 'inspire_local_storage_setting_',
	},
	getters: {
		// allow beta_options to be specified in mapGetters
		beta_options:(state) => { return state.lst.beta_options },

		// sparkl_student_activities_enabled:(state) => { return state.lst.beta_options.sparkl_student_activities },
		// resource_repositories_enabled:(state) => { return state.lst.beta_options.resource_repositories },
		// new_course_index_enabled:(state) => { return state.lst.beta_options.new_course_index },

		academic_year:(state) => { return state.user_info.academic_year },
		academic_year_display:(state) => { 
			let start_year = (state.user_info.academic_year) || state.default_academic_year
			return `${start_year}-${start_year*1+1}`
		},
		signed_in:(state) => {
			return (state.user_info.user_id > 0)
		},
		// the user_info.system_role value indicates the highest role the user can adopt
		system_role:(state) => { return state.user_info.system_role },
		// but staff and admin users can adopt different roles; so usually when we want to do something that's role-based, we look at the user_info.role value, which reflects the role the user has currently chosen to adopt
		role:(state) => { return state.user_info.role },
		studentish_role:(state) => { return (state.user_info.role == 'student' || state.user_info.role == 'parent') },
		simulating_user:(state) => { return !empty(state.lst.simulated_user) },
		user_is_principal_or_ap:(state) => {
			// deal with misspellings
			return state.user_info.district_role.some(x=>(x == 'principal' || x == 'assistant principal' || x == 'principle' || x == 'assistant principle'))
		},
		show_all_items_when_not_signed_in:(state) => { return vapp.site_config.show_all_items_when_not_signed_in == 'yes' },
		use_term_mode:(state) => { return vapp.site_config.use_term_mode == 'yes' },
		small_screen:(state) => {
			// let val = vapp.$vuetify.breakpoint.height < 600 || vapp.$vuetify.breakpoint.width < 600
			// PW 8/13/2024: just check width; the height can get to < 600 pretty easily if you have a short monitor and/or if you increase the magnification of your screen.
			let val = vapp.$vuetify.breakpoint.width < 600
			return val
		},
		// Family view getters:
		child_count:(state) => {
			let i = 0
			for (let child_email in state.user_info.child_data) ++i
			return i
		},
		child_data_showing:(state) => {
			if (state.user_info.child_data) {
				// if user has selected a child, return it
				if (state.lst.child_email_showing) return state.user_info.child_data[state.lst.child_email_showing]
				else {
					// else return the first child listed
					for (let email in state.user_info.child_data) {
						return state.user_info.child_data[email]
					}
				}
			}
			// if we get to here return an empty object
			return {}
		},
		// URL of websocket server
		// All prod DNSes route messages to wss://henryconnect.net/wss2
		henry_chatter_url:(state) => {
			let hc_url = 'ws://localhost:8082'
			if ('https://dev-tassle.commongoodlt.com/' == window.location.hostname) hc_url = 'wss://https://dev-tassle.commongoodlt.com//wss2'
			// prod socket messages all go though henryconnect.net
			else if ((window.location.hostname).includes('henry')) hc_url = 'wss://henryconnects.net/wss2'
			return hc_url
		},

		app_name_with_indefinite_article:(state) => {
			// In some instances, the App Title is prefixed by an indefinite article, e.g. "an Inspire system administrator"
			// In those cases, we add the article here based on a regex
			return `${/^[aeiou]/i.test(state.site_config.app_name) ? 'an' : 'a'} ${state.site_config.app_name}`
		},

		my_default_collection:(state, getters) => {
			// students don't have a default collection
			if (getters.studentish_role) return null

			if (state.user_info.user_id == 0) return null
			// we currently store the signed-in user's dummy default collection in all_courses[0]
			return state.all_courses[0]
		},

		manage_assignments:(state) => {
			// return false
			// // TEMP: only allow for this in local development
			// if (window.location.host.indexOf('localhost') == -1) return false

			return state.site_config.manage_assignments == 'yes'
		},

		// determine the current_term based on the term_settings and today's date
		current_term:(state) => {
			// return the first term for which today is within the start_date and end_date
			// note that we index terms starting at 1 (1, 2, 3, or 4)
			let today = date.format(state.now_date_obj, 'YYYY-MM-DD')
			for (let i = 0; i < state.term_settings.term_dates.length; ++i) {
				let td = state.term_settings.term_dates[i]
				if (today >= td.start_date && today <= td.end_date) {
					return (i+1)
				}
			}
		},

		activities_for_student:(state, getters) => {
			// If  role is parent, we only want to show activities for the current student...
			if (state.user_info.role == 'parent') {
				let arr = []
				for (let activity of state.my_activities) {
					let sc = getters.child_data_showing.sis_student_classes
					if (!sc || sc.findIndex(x=>x.course_code == activity.course_code) == -1) {
						continue
					}
					arr.push(activity)
				}
				return arr
			}
		},
		
		// list of the collections for courses the user is teaching/taking only, according to the sis
		my_sis_course_collections:(state, getters) => {
			let arr = []
			for (let course of state.sis_classes) {
				// filter out courses with code 'xxx'
				if (course.course_code == 'xxx') continue
				// filter out duplicates
				if (arr.find(x=>x.course_code == course.course_code)) continue
				// filter out gifted courses ?? -- no; currently (8/2024) we don't seem to be able to distinguish between honors and gifted courses
				// if (course.is_gifted_course()) continue
					
				let lp = state.all_courses.find(x=>x.course_code == course.course_code)

				// if we don't have an lp for this course_code...
				if (empty(lp)) {
					// skip it for staff/admin, but show for parent/student?
					if (state.role == 'staff' || state.role == 'admin') continue

					// no, just skip it altogether
					// continue

				} else if (lp.active == 'no') {
					// // for inactive lp's, only show to admins
					// if (!lp.user_is_lp_admin()) {
					// 	continue
					// }
				}

				// for student/parent, only show courses for current term (teachers always see all the courses they're teaching throughout the year)
				if (state.user_info.role == 'student' || state.user_info.role == 'parent') {
					// let current = true
					// let today = '2024-05-01'
					let today = date.format(state.now_date_obj, 'YYYY-MM-DD')
					let current = false
					for (let i = 0; i < state.term_settings.term_dates?.length; ++i) {
						// if today is within the start_date and end_date of any term...
						let td = state.term_settings.term_dates[i]
						if (today >= td.start_date && today <= td.end_date) {
							// ...then if this course matches the term...
							if (course.class_matches_term(i+1)) {
								// this is a current course, so show it
								current = true
								break
							}
						}
					}
					if (!current) {
						// console.warn('course doesn’t match term', extobj(course))
						continue
					}

					// for parent role, also only show classes for the currently-selected student
					if (state.user_info.role == 'parent') {
						let sc = getters.child_data_showing.sis_student_classes
						if (!sc || sc.findIndex(x=>x.course_code == course.course_code) == -1) {
							continue
						}
					}
				}

				arr.push(course)
			}
			return arr
		},

		// MESSAGE CENTER RELATED GETTERS
		use_message_center:(state) => {
			return state.site_config.use_message_center == 'yes'
		},
		threaded_messages:(state) => {
			const threads = state.messages?.filter(message => message.message_level*1 === 0)
				.map(message => ({ ...message, replies: [] })) ?? []
			const level_one_messages = state.messages?.filter(message => message.message_level*1 === 1)
				.map(message => ({ ...message, replies: [] })) ?? []
			const level_two_messages = state.messages?.filter(message => message.message_level*1 === 2) ?? []

			level_two_messages.forEach(message => {
				const match = level_one_messages.find(a => a.message_id === message.parent_message_id)
				if (match) {
					match.replies.push(message)
				}
			})
			level_one_messages.forEach(message => {
				const match = threads.find(a => a.message_id === message.parent_message_id)
				if (match) {
					match.replies.push(message)
				}
			})

			return threads.map(thread => new window.Message(thread))
		},
		messages_for_student:(state, getters) => {
			// if role is parent, we only want to show messages for the selected student...
			if (state.user_info.role == 'parent') {
				const student_sourcedId = getters.child_data_showing.sourcedId
				if (!student_sourcedId) return []
				let arr = []
				for (let message of getters.threaded_messages) {
					if (message.recipients?.map(recipient => recipient.user_sourcedId)?.includes(student_sourcedId)) {
						arr.push(message)
					}
				}
				return arr
			}
		},

		unread_message_count:(state) => (course_code = "") => {
			return state.messages.filter(message =>
				!message.is_read &&
				message.message_id !== 0 &&
				(course_code == "" || message.course_code == course_code)
			).length
		},

		// SHORTHAND COLOR VALUES FROM site_config
		// This was useful when migrating Henry functionality to Cureum
		primary_color:(state) => {
			return state.site_config.vue_primary_color
		},
		secondary_color:(state) => {
			return state.site_config.vue_secondary_color
		},

		// // collection records for the courses the sis says I'm taking
		// my_sis_course_collections:(state, getters) => {
		// 	let arr = []
		// 	for (let item of getters.my_sis_courses) {
		// 		let 
		// 		// filter out courses in removed_my_courses
		// 		if (state.removed_my_courses.find(x=>x == lp.course_code)) continue
		// 		arr.push(lp)
		// 	}

		// 	// add courses whose course_codes are in added_my_courses (and which we haven't yet processed above)
		// 	for (let course_code of state.added_my_courses) {
		// 		// skip if we already processed the class above -- the class might, e.g., have been added to the teacher's sis_classes after they explicitly added it
		// 		if (arr.find(x=>x.course_code==course_code)) continue

		// 		let c = state.all_courses.find(x=>x.course_code == course_code)
		// 		if (!empty(c)) {
		// 			arr.push(new User_Course({course_code: course_code, titles: [c.title]}))
		// 		}
		// 	}
		// 	return arr

		// 	// NOTE: sis_classes will appear first, in alpha order, followed by added classes
		// },
	},
	mutations: {
		set(state, payload) {
			// this.$store.commit('set', ['key', val])
			// update state property 'key' to value 'val'
			if (payload.length == 2) {
				state[payload[0]] = payload[1]
				return
			}

			var o = payload[0]
			var key = payload[1]
			var val = payload[2]

			// this.$store.commit('set', ['obj', 'key', val])
			// update property 'key' of 'o' to value 'val'
			if (typeof(o) == 'string') {
				if (state[o][key] == undefined) Vue.set(state[o], key, val)
				else state[o][key] = val
				return
			}

			// this.$store.commit('set', [obj, 'key', true])
			// this.$store.commit('set', [obj, ['level_1_key', 'level_2_key'], true])
			// this.$store.commit('set', [obj, 'PUSH', 1])	// push 1 onto obj, which must be an array in this case
			// update property of obj, **WHICH MUST BE PART OF STATE!**
			if (typeof(key) == 'string') {
				if (key == 'PUSH') {
					o.push(val)
				} else if (key == 'UNSHIFT') {
					o.unshift(val)
				} else if (key == 'SPLICE') {
					// if we got a fourth value in payload, add that value into the array; otherwise just take the val-th item out
					if (!empty(payload[3])) {
						o.splice(val, 1, payload[3])
					} else {
						o.splice(val, 1)
					}
				} else if (val == '*DELETE_FROM_STORE*') {
					// delete the val if it existed (if it didn't exist, we don't have to do anything)
					if (o[key] != undefined) Vue.delete(o, key)
				} else if (o[key] == undefined) {
					Vue.set(o, key, val)
				} else {
					o[key] = val
				}
			} else {
				for (var i = 0; i < key.length-1; ++i) {
					o = o[key[i]]
					if (empty(o)) {
						console.log('ERROR IN STORE.SET', key, val)
						return
					}
				}
				if (o[key[i]] == undefined) Vue.set(o, key[i], val)
				else o[key[i]] = val
			}

			// samples:
			// this.$store.commit('set', [this.exercise, ['temp', 'editing'], true])
			// this.$store.commit('set', [this.qstatus, 'started', true])
		},

		add_to_array(state, payload) {
			// add to the array, checking first to make sure it's not already there
			// this.$store.commit('add_to_array', [array, old_val])
			let arr = payload[0]
			let val = payload[1]

			// if it doesn't already exist, add to the array
			if (arr.findIndex(x=>x==val) == -1) {
				arr.push(val)
			}
		},

		replace_in_array(state, payload) {
			// this.$store.commit('replace_in_array', [array, old_val, new_val])
			let arr = payload[0]

			// try to find the index of the old_val; caller can send either a value to look for directly, or a property and a value
			let i, new_val
			if (payload.length == 3) {
				let old_val = payload[1]
				new_val = payload[2]
				i = arr.findIndex(x=>x==old_val)
			} else {
				let prop = payload[1]
				let old_val = payload[2]
				new_val = payload[3]
				i = arr.findIndex(x=>x[prop]==old_val)
			}

			if (i > -1) {
				// if found, replace with new_val; have to use splice for reactive arrays (see vue documentation)
				arr.splice(i, 1, new_val)
			} else {
				// else push
				arr.push(new_val)
			}
		},

		splice_from_array(state, payload) {
			// this.$store.commit('splice_from_array', [array, old_val])
			let arr = payload[0]
			let old_val = payload[1]

			// try to find the index of the old_val
			let i = arr.findIndex(x=>x==old_val)
			if (i > -1) {
				// if found, replace with new_val; have to use splice for reactive arrays (see vue documentation)
				arr.splice(i, 1)
			}
		},

		splice_from_array_by_index(state, payload) {
			// this.$store.commit('splice_from_array', [array, old_val])
			let arr = payload[0]
			let i = payload[1]

			arr.splice(i, 1)
		},

		// consolidate students from all sis_classes into a single associative array
		process_students_from_sis_classes(state) {
			let o = {}
			for (let cl of state.sis_classes) {
				if (empty(cl.students)) continue
				for (let i = 0; i < cl.students.length; ++i) {
					let student_list = cl.students[i]
					if (empty(student_list)) continue
					for (let student of student_list) {
						if (empty(o[student.sourcedId])) {
							o[student.sourcedId] = student
							o[student.sourcedId].class_sourcedIds = [cl.class_sourcedIds[i]]
						} else {
							o[student.sourcedId].class_sourcedIds.push(cl.class_sourcedIds[i])
						}
					}
				}
			}
			state.all_students = o
		},

		trigger_course_update(state) {
			state.course_update_trigger += 1
		},

		// fns to initialize and set local_storage settings
		lst_initialize(state) {
			for (let key in state.lst) {
				let val = U.local_storage_get(state.lst_prefix + key)
				if (!empty(val)) {
					state.lst[key] = val
				}
			}

			// initialize beta_options specially, given that the options here will change periodically
			if (state.lst.beta_options) {
				// console.log(JSON.stringify(state.lst.beta_options))
				for (let key in state.default_beta_options) {
					if (state.lst.beta_options[key] === undefined) {
						Vue.set(state.lst.beta_options, key, state.default_beta_options[key])
					}
				}
			}
		},

		// this.$store.commit('lst_set', ['mc_mode', 'bubbles'])
		lst_set(state, payload) {
			let key, val
			if (typeof(payload) == 'string') {
				// if a single string value is sent in, we just save in local_storage; presumably the changed value will have been already saved via set
				U.local_storage_set(state.lst_prefix + payload, state.lst[payload])
			}

			if (Array.isArray(payload)) {
				key = payload[0]
				val = payload[1]
			} else {
				key = payload.key
				val = payload.val
			}

			// save in state
			state.lst[key] = val

			// now save in local_storage
			U.local_storage_set(state.lst_prefix + key, val)
		},

		lst_clear(state, key) {
			U.local_storage_clear(state.lst_prefix + key)
		},

		write_site_config(state) {
			let style_rules = [
				sr(' :root { --agency-name-font-family: $1; }', state.site_config.agency_name_font_family),
				sr('.k-main-wrapper .k-app-toolbar .k-tassle-logo-text { $1 }', state.site_config.banner_logo_css),
				sr('.k-main-wrapper .k-app-toolbar .k-toolbar__logo-img { $1 }', state.site_config.agency_img_css),
				// note that k-login__logo-text and k-login__logo-img won't be used for oidc login
				sr('.k-login-wrapper { $1 }', state.site_config.login_wrapper_css),
				sr('.k-login__logo-img { $1 }', state.site_config.login_logo_img_css),
				sr('.k-login__logo-text { $1 }', state.site_config.login_logo_text_css),
				sr('.k-main-wrapper .k-app-toolbar { background-color: $1 !important; }', state.site_config.banner_color),
				sr('.k-main-wrapper .k-app-toolbar .k-toolbar__username { color: $1; }', state.site_config.banner_user_name_color),

				sr('.k-main-welcome .k-main-welcome--nav-btns .k-main-welcome--nav-btn { background-color: $1; }', state.site_config.vue_primary_color),
				sr('.k-main-welcome .k-main-welcome--nav-btns .k-main-welcome--nav-btn-current .v-icon { color: $1; }', state.site_config.vue_primary_color),
				sr('.k-main-wrapper .k-mini-nav .v-btn { background-color: $1 !important; }', state.site_config.vue_secondary_color),
				sr('.k-main-wrapper .k-mini-nav .k-mini-nav-current-btn i { color: $1 !important; }', state.site_config.vue_primary_color),
				sr('.k-main-wrapper .k-mini-nav .k-mini-nav-current-btn { background-color: transparent !important; }'),

				// not sure if we will ever need these, but just in case...
				`.k-primary-color-background { background-color:${state.site_config.vue_primary_color}; }`,
				`.k-primary-color-text { color:${state.site_config.vue_primary_color}; }`,
				`.k-secondary-color-background { background-color:${state.site_config.vue_secondary_color}; }`,
				`.k-secondary-color-text { color:${state.site_config.vue_secondary_color}; }`,
			]

			$('head').append('<style type="text/css">' + style_rules.join('\n') + '</style>');

			document.title = state.site_config.index_title
			document.getElementById('agency-name-font-family').href = state.site_config.agency_name_font_family_href

			// copy grades and subjects from site_config to state, for coding convenience
			state.grades = state.site_config.grades

			vapp.$vuetify.theme.themes.dark.primary = vapp.$vuetify.theme.themes.light.primary = vapp.$vuetify.theme.defaults.dark.primary = vapp.$vuetify.theme.defaults.light.primary = state.site_config.vue_primary_color
			vapp.$vuetify.theme.themes.dark.secondary = vapp.$vuetify.theme.themes.light.secondary = vapp.$vuetify.theme.defaults.dark.secondary = vapp.$vuetify.theme.defaults.light.secondary = state.site_config.vue_secondary_color

			// write updated svg for spinner with colors provided in site_config
			let spinner_color_1 = state.site_config.spinner_color_1 || state.site_config.vue_primary_color
			let spinner_color_2 = state.site_config.spinner_color_2 || state.site_config.vue_secondary_color
			let svg = `<svg id="spinner" style="position:absolute; left:50%; top:50%; margin: -50px 0 0 -50px; transform:scale(2); background: none;" width="100px"  height="100px"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" class="lds-ripple"><circle cx="50" cy="50" r="21.2875" fill="none" ng-attr-stroke="{{config.c1}}" ng-attr-stroke-width="{{config.width}}" stroke="${spinner_color_1}" stroke-width="5"><animate attributeName="r" calcMode="spline" values="0;40" keyTimes="0;1" dur="1" keySplines="0 0.2 0.8 1" begin="-0.5s" repeatCount="indefinite"></animate><animate attributeName="opacity" calcMode="spline" values="1;0" keyTimes="0;1" dur="1" keySplines="0.2 0 0.8 1" begin="-0.5s" repeatCount="indefinite"></animate></circle><circle cx="50" cy="50" r="38.2118" fill="none" ng-attr-stroke="{{config.c2}}" ng-attr-stroke-width="{{config.width}}" stroke="${spinner_color_2}" stroke-width="5"><animate attributeName="r" calcMode="spline" values="0;40" keyTimes="0;1" dur="1" keySplines="0 0.2 0.8 1" begin="0s" repeatCount="indefinite"></animate><animate attributeName="opacity" calcMode="spline" values="1;0" keyTimes="0;1" dur="1" keySplines="0.2 0 0.8 1" begin="0s" repeatCount="indefinite"></animate></circle></svg>`
			$('#spinner-wrapper').html(svg)

			// set state.login_msg to site_config.login_msg; that msg could get changed
			state.login_msg = state.site_config.login_msg
		},

		generate_default_collection(state) {
			// create user's "default collection" from incoming default_collection_lessons and default_collection_resources
			let dc = new Learning_Progression({
				fully_loaded: true,
				lp_id: 1,
				collection_type: 'my',
				lp_layout: 'tree',
				use_terms: false,
				use_unit_numbers: false,
				use_unit_intervals: false,
				updated_at: '2023-11-02 00:00:00',
				course_code: 'default',
				title: state.site_config.default_my_collection_label,
				color: 'default',
				units: [
					// note "magic constants" for fake lp_unit_ids for lessons, sparkls, and link resources
					new LP_Unit({lp_unit_id: 2, title: 'Lessons'}),
					new LP_Unit({lp_unit_id: 3, title: `${state.site_config.sparkl_app_name} Student Activities`}),
					new LP_Unit({lp_unit_id: 4, title: 'Other Resources'}),
				],
			})
			// get the lp_id value used on the server for the user's default collection (we use lp_id 1 here on the client)
			let default_collection_id = dc.default_collection_id_for_collection_asset_mapping()
			let other_collection_lessons = []
			for (let item of state.my_lessons) {
				// get the cam record for the item
				let cam = state.my_ca_mappings.find(x=>x.asset_id == item.lesson_id)

				// if the item is actually from another collection, add to a special folder
				if (cam && cam.collection_id != default_collection_id) other_collection_lessons.push(item)
				// else it was created in the default collection
				else {
					dc.units[0].lessons.push(item)
					dc.units[0].add_item_to_folder({parent_folder_id:'top', type:'lesson', resource_id:item.lesson_id})
				}
			}

			let other_collection_sparkl = []
			let other_collection_resources = []
			for (let item of state.my_resources) {
				// get the cam record for the item
				let cam = state.my_ca_mappings.find(x=>x.asset_id == item.resource_id)

				if (item.type == 'sparkl') {
					// if the item is actually from another collection, add to a special folder
					if (cam && cam.collection_id != default_collection_id) other_collection_sparkl.push(item)
					// else it was created in the default collection
					else {
						dc.units[1].resources.push(item)
						dc.units[1].add_item_to_folder({parent_folder_id:'top', type:'resource', resource_id:item.resource_id})
					}

				} else {
					// if the item is actually from another collection, add to a special folder
					if (cam && cam.collection_id != default_collection_id) other_collection_resources.push(item)
					// else it was created in the default collection
					else {
						dc.units[2].resources.push(item)
						dc.units[2].add_item_to_folder({parent_folder_id:'top', type:'resource', resource_id:item.resource_id})
					}
				}
			}

			// if we got other_collection_xxx's, make a folder for each one
			if (other_collection_lessons.length > 0) {
				dc.units[0].lessons = dc.units[0].lessons.concat(other_collection_lessons)
				dc.units[0].create_resource_folder({parent_folder_id:'top', title:'Lessons that you’ve created from other collections', folder_id:`default_content_other_collection_lessons_${state.user_info.user_id}`, items:other_collection_lessons})
			}
			if (other_collection_sparkl.length > 0) {
				dc.units[1].resources = dc.units[1].resources.concat(other_collection_sparkl)
				dc.units[1].create_resource_folder({parent_folder_id:'top', title:`${state.site_config.sparkl_app_name} Student Activities that you’ve created from other collections`, folder_id:`default_content_other_collection_sparkl_${state.user_info.user_id}`, items:other_collection_sparkl})
			}
			if (other_collection_resources.length > 0) {
				dc.units[2].resources = dc.units[2].resources.concat(other_collection_resources)
				dc.units[2].create_resource_folder({parent_folder_id:'top', title:'Resources that you’ve created from other collections', folder_id:`default_content_other_collection_resources_${state.user_info.user_id}`, items:other_collection_resources})
			}
			
			// note that everything will get sorted by CollectionResourceFolder when the default collection is viewed

			// replace or add default collection in all_courses
			if (state.all_courses[0] && state.all_courses[0].lp_id == 1) {
				state.all_courses.splice(0, 1, dc)
			} else {
				state.all_courses.unshift(dc)
			}
		},

		// this fn is set to run once a minute by initialize_app
		set_now_date(state) {
			// use now_date_obj everywhere instead of Date(), so that we can simulate a certain date for demo purposes
			// try to get simulated_date from lst
			state.simulated_date = state.lst.simulated_date		// '2023-02-04'
			if (!state.simulated_date) state.now_date_obj = new Date()
			else state.now_date_obj = date.parse(state.simulated_date + ' ' + date.format(new Date(), 'HH:mm:ss'), 'YYYY-MM-DD HH:mm:ss')
			
			// set now_date_string and old_threshold_date_string to the dates format needed to compare to lesson/activity dates
			state.now_date_string = date.format(state.now_date_obj, 'YYYY-MM-DD')
			state.old_threshold_date_timestamp = Math.round((state.now_date_obj - 7*24*60*60*1000) / 1000)
			state.old_threshold_date_string = date.format(new Date(state.now_date_obj - 7*24*60*60*1000), 'YYYY-MM-DD')	// one week prior to now
		},

		add_to_my_activities(state, activity) {
			if (activity.collection_id) {
				if (!state.my_activities_by_course[activity.collection_id]) {
					Vue.set(state.my_activities_by_course, activity.collection_id, [])
				}
				state.my_activities_by_course[activity.collection_id].push(activity)
			}
			state.my_activities.push(activity)
		},

		replace_in_my_activities(state, activity) {
			let index = state.my_activities.findIndex(x => x.activity_id == activity.activity_id)
			state.my_activities.splice(index, 1, activity)

			if (activity.collection_id && state.my_activities_by_course[activity.collection_id]) {
				let index = state.my_activities_by_course[activity.collection_id].findIndex(x => x.activity_id == activity.activity_id)
				state.my_activities_by_course[activity.collection_id].splice(index, 1, activity)
			}
		},

		remove_from_my_activities(state, activity) {
			let index = state.my_activities.findIndex(x => x.activity_id == activity.activity_id)
			state.my_activities.splice(index, 1)

			if (activity.collection_id && state.my_activities_by_course[activity.collection_id]) {
				let index = state.my_activities_by_course[activity.collection_id].findIndex(x => x.activity_id == activity.activity_id)
				state.my_activities_by_course[activity.collection_id].splice(index, 1)
			}
		},

		update_collection_type(state, { index, lp }) {
			if (state.all_courses[index]) {
				state.all_courses[index].collection_type = lp.collection_type
				state.all_courses[index].updated_at = lp.updated_at
			}
		},

	},
	actions: {
		initialize_app({state, commit, dispatch}, payload) {
			// detect when user touches screen
			// https://codeburst.io/the-only-way-to-detect-touch-with-javascript-7791a3346685
			window.addEventListener('touchstart', function on_first_touch() {
				vapp.$store.commit('set', ['user_is_touching', true])
				// we only need to know once that a human touched the screen, so we can stop listening now
				window.removeEventListener('touchstart', on_first_touch, false);
			}, false);

			commit('set_now_date')
			setInterval(() => { commit('set_now_date') }, 60*1000)

			// if we're simulating another user, send in the simulated user's email here
			if (state.lst.simulated_user) payload.simulated_user = state.lst.simulated_user

			return new Promise((resolve, reject)=>{
				// BYPASS INITIALIZATION -- note that you won't actually be able to do anything nefarious if this code runs, because the server will know you're not an admin
				if (false) {
					commit('set', ['user_info', new User_Info({
						user_id: 1,
						first_name: 'Pepper',
						last_name: 'Williams',
						email: 'pw@pw.com',
						system_role: 'admin',
						role: 'admin',
					})])
					resolve('main')
					return
				}

				U.ajax('initialize_app', payload, result=>{
					if (result.status != 'ok') {
						console.log('Error in initialization!')
						reject()
						return
					}
					console.log('Initialized!', result)

					if (state.lst.simulated_user) {
						state.simulated_user_info = result.simulated_user_info
						if (!state.simulated_user_info) {
							vapp.$inform(`We were not able to simulated the chosen user ${state.lst.simulated_user}.`)
							commit('lst_set', ['simulated_user', ''])
						} else {
							state.actual_user_info = result.user_info
							result.user_info = result.simulated_user_info
						}
					}

					if (result.site_config) {
						// set property-by-property, so that we retain default values set above
						for (let key in result.site_config) {
							commit('set', [state.site_config, key, result.site_config[key]])
						}
					} else console.log('site_config not received!');
					commit('write_site_config')

					// if we're simulating another user and enable_sandbox_simulate_role_menu is 'yes',
					if (state.lst.simulated_user && state.site_config.enable_sandbox_simulate_role_menu == 'yes') {
						// make sure we go to 'favorites' for the course view, and make sure that the courseindex page is reset
						state.lst.course_index_view_flavor =  'favorites'
						state.lst.my_index_view_flavor =  'index'
						state.lst.repo_index_view_flavor =  'index'
						state.lst.courseindex_opened_category = null
						state.lst.courseindex_opened_subcats = {}

						// and show an alert
						vapp.$alert({
							title: 'Heads Up!',
							text: 'Please do NOT make changes or create content while simulating this user role, as such changes would impact other sandbox users using the role simulation functionality.<div class="mt-2">(But please <b>DO</b> make changes and create content when viewing the sandbox using your Demo Staff Account!)</div>',
							acceptText: 'Got It!',
							acceptIcon: 'fas fa-face-smile'
						})
					}
		
					// result should always include the google_client_id (though it could be empty)
					commit('set', ['google_client_id', result.google_client_id])

					// include the term_settings from config file
					if (result.term_settings) {
						// hard code weeks for now; have to build this into the admin tool
						result.term_settings.weeks = [
							['2024-08-01', '2024-08-09'],
							['2024-08-12', '2024-08-16'],
							['2024-08-19', '2024-08-23'],
							['2024-08-26', '2024-08-30'],
							['2024-09-03', '2024-09-06'],
							['2024-09-09', '2024-09-13'],
							['2024-09-23', '2024-09-27'],
							['2024-09-30', '2024-10-04'],
							['2024-10-07', '2024-10-11'],
			
							['2024-10-14', '2024-10-18'],
							['2024-10-21', '2024-10-25'],
							['2024-10-28', '2024-11-01'],
							['2024-11-04', '2024-11-08'],
							['2024-11-11', '2024-11-15'],
							['2024-11-18', '2024-11-22'],
							['2024-12-02', '2024-12-06'],
							['2024-12-09', '2024-12-13'],
							['2024-12-16', '2024-12-20'],
			
							['2024-01-08', '2024-01-10'],
							['2024-01-13', '2024-01-17'],
							['2024-01-21', '2024-01-24'],
							['2024-01-27', '2024-01-31'],
							['2024-02-03', '2024-02-07'],
							['2024-02-10', '2024-02-14'],
							['2024-02-24', '2024-02-28'],
							['2024-03-03', '2024-03-07'],
							['2024-03-10', '2024-03-14'],
			
							['2024-03-17', '2024-03-21'],
							['2024-03-24', '2024-03-28'],
							['2024-03-31', '2024-04-04'],
							['2024-04-14', '2024-04-18'],
							['2024-04-21', '2024-04-25'],
							['2024-04-28', '2024-05-02'],
							['2024-05-05', '2024-05-09'],
							['2024-05-12', '2024-05-16'],
							['2024-05-19', '2024-05-23'],
						]
			
						commit('set', ['term_settings', result.term_settings])
					}

					state.froala_key = result.froala_key

					commit('set', ['available_academic_years', result.available_academic_years])
					commit('set', ['default_academic_year', result.default_academic_year])
					commit('set', ['allow_academic_year_change_for_all_staff', result.allow_academic_year_change_for_all_staff])
					commit('set', ['todo_user_group_divisions', result.todo_user_group_divisions])
					commit('set', ['todo_user_group_schools', result.todo_user_group_schools])
					commit('set', ['subjects', result.subjects])


					// if we didn't receive user_info, the user is not already logged in...
					if (empty(result.user_info)) {
						// if user isn't already logged in and get string includes 'login', go right to login (after first clearing the login flag)
						if (U.check_get_string_param('login')) {
							U.clear_location_search()
							vapp.redirect_to_login()
							return
						}
						// create a dummy user_info record
						commit('set', ['user_info', new User_Info()])

						// restore added_my_courses from localstorage; we may want to do other things here
						commit('set', ['added_my_courses', state.lst.local_added_my_courses])


						// handle logging in with a token
						// if we just attempted a token signin...
						if (result.token_result) {
							// for token errors, we just inform the user of the error and proceed with initialization
							if (result.token_result != 'ok') {
								console.log(result.token_result)
								let msg = 'The one-time sign-in link you clicked did not work'
								if (result.token_result == 'token_expired') msg += ', because the link has expired'
								else if (result.token_result == 'token_not_found') msg += ', possibly because the link was already used once'
								else if (result.token_result == 'head_request') msg += ' (bad request type)'	// this should never happen in the real world
								msg += '.'
								// open the sign in dialog after they dismiss the error message
								vapp.$alert({title:'<span class="red--text text--darken-2">Sign-In Link Error</span>', text:msg}).then(U.clear_location_search())
							} 
						}

						// if we receive a login_error, the user tried to log in directly to cureum with a username/password and didn't succeed, so go back to login mode and show the error
						if (!empty(result.login_error)) {
							commit('set', ['login_error', result.login_error])
							resolve('login')
							return
						}

						// else if site_config.down_until is set, simulate a login_error with down_until (but let the user sign in using a magic password)
						if (!empty(state.site_config.down_until)) {
							commit('set', ['login_error', `${state.site_config.app_name} is temporarily down for maintenance. We should be back online by <b>${state.site_config.down_until}</b>.`])
							resolve('login')
							return
						}


						// if we're here and site_config.require_sign_in is 'yes' or show_signin_on_load is 'yes', resolve to the login screen
						if (state.site_config.require_sign_in == 'yes' || state.site_config.show_signin_on_load == 'yes') {
							resolve('login')
							return
						}

					} else {
						// if we get to here, user is already logged in; clear the search string if necessary
						if (U.check_get_string_param('login')) U.clear_location_search()
						
						// else we received user_info.  if we have a simulated_role in lst, add it to user_info; if this is '' (the default value), role will be set to system_role
						result.user_info.role = state.lst.simulated_role
						commit('set', ['user_info', new User_Info(result.user_info)])
	
						if (result.token_result) {
							// else offer to let the user change their password
							let msg = 'You have been signed in by the one-time link you clicked. Would you like to create or change your ' + state.site_config.app_name + ' password at this time?'
							// Confirmation with property overrides
							vapp.$confirm({
								text: msg,
								acceptText: 'Change Password',
								cancelText: 'No thank you',
								dialogMaxWidth: 600,
							}).then(y => {

								vapp.$prompt({
									title: 'Change Password',
									text: 'Enter the new password you would like to use for your <nobr>' + state.site_config.app_name + '</nobr> account:',
									password: true,
									acceptText: 'Use this password',
								}).then(password => {
									if (!empty(password)) {
										vapp.$prompt({
											title: 'Confirm New Password',
											text: 'Please confirm the new password you just entered:',
											password: true,
											acceptText: 'Confirm and Save new password',
										}).then(new_password => {
											if (password != new_password) {
												vapp.$alert('The two passwords you entered do not match!')// .then(x=>this.change_password())
												return
											}
					
											let payload = {
												user_id: state.user_info.user_id,
												email: state.user_info.email,
												password: new_password,
											}
					
											U.loading_start()
											U.ajax('change_password', payload, result=>{
												U.loading_stop()
												if (result.status != 'ok') {
													console.log('Error in admin ajax call'); vapp.ping(); return;
												}
					
												vapp.$alert({title:'Success!', text:'Password changed.'})
											});
					
										}).catch(n=>{console.log(n)}).finally(f=>{})
									}
								}).catch(n=>{console.log(n)}).finally(f=>{})
													
							}).catch(n=>{console.log(n)}).finally(f=>{
								// regardless of which option is chosen, call clear_login_token service to clear the login token now
								U.ajax('clear_login_token', {user_id: state.user_info.user_id, email:payload.email, token:payload.token})
							})
						}



						// if the user signed in via cureum, we receive a session_id when the user first signs in; set it here in U and in localstorage
						// for OIDC login, we handle this in app->check_session
						if (result.session_id) {
							U.session_id = result.session_id
							U.local_storage_set('gaconnects_session_id', U.session_id)
						}

						// if we just signed in with a password, reload the window, because we load some things differently depending on whether you're signed in or not
						if (!empty(payload.password) || !empty(payload.google_id_token)) {
							document.location.reload()
							return
						}
					}
					if (state.user_info.user_id) U.ajax('set_last_session_id', {user_id: state.user_info.user_id, session_id: U.session_id})

					resolve('main')
				});
			})
		},

		get_classes({state, commit, dispatch, getters}) {
			return new Promise((resolve, reject)=>{
				let payload
				// if signed in...
				if (getters.signed_in) {
					payload = {
						user_id: state.user_info.user_id,
						system_role: state.user_info.system_role,	// we might be simulating another user; if so, for get_classes, we want to simulate that other user's system_role and role
						role: state.user_info.role,
					}

					// also add modified list of oidc_data.course_codes	"CourseCodes": "23.1050000,23.0050000,00.0000009,00.0000013,27.1150000,27.0150000,23.1016000,23.0016000,41.0150000,45.0050000",
					if (state.user_info.oidc_data?.course_codes) {
						let arr = state.user_info.oidc_data.course_codes.split(',')
						for (let i = 0; i < arr.length; ++i) {
							// course codes in the cureum DB are always (hopefully) coded with 4 digits after the period
							arr[i] = arr[i].replace(/(\.\d\d\d\d).*/, '$1')
						}
						payload.oidc_course_codes = arr
					}

					// for students, send in sis_user_sourcedId and sis_class_sourcedIds
					if (state.user_info.system_role == 'student') {
						payload.sis_user_sourcedId = state.user_info.sis_user_sourcedId
						payload.sis_class_sourcedIds = state.user_info.sis_class_sourcedIds
					}

				// else not signed in...
				} else {
					// currently we just resolve, because we're not allowing users to add courses locally...
					state.unsigned_index_view_flavor = 'lpindex'
					state.loaded_classes = true
					resolve()
					return
					// ... but we could send added_my_courses, which will have been retrieved from localstorage, if we have any
					if (state.added_my_courses.length == 0) {
						state.unsigned_index_view_flavor = 'lpindex'
						state.loaded_classes = true
						resolve()
						return
					}
					// otherwise send added_my_courses in
					payload = {
						added_my_courses: state.added_my_courses
					}
				}

				U.loading_start()
				U.ajax('get_classes', payload, result=>{
					U.loading_stop()
					if (result.status != 'ok') {
						console.log('Error retrieving class data')
						vapp.ping()		// call ping to check if the session is expired
						reject()
						return
					}
					console.log('get_classes', result)

					// add added_my_courses and removed_my_courses
					if (!empty(result.added_my_courses)) commit('set', ['added_my_courses', result.added_my_courses])
					if (!empty(result.removed_my_courses)) commit('set', ['removed_my_courses', result.removed_my_courses])

					// if we didn't get any sis_classes OR added_my_courses, set unsigned_index_view_flavor to 'lpindex', since we don't have any classes to show
					if (result.classes.length == 0 && state.added_my_courses.length == 0) {
						state.unsigned_index_view_flavor = 'lpindex'

					} else {
						// for some instances (e.g. Henry) we will get a full sis record for each class; for others (e.g. Inspire) we only get a course_code for each class; 
						// use sis_classes and the User_Course model to keep things consistent either way
						if (typeof(result.classes[0]) == 'string') {
							for (let course_code of result.classes) {
								// only push here if we actually got an LP for each one
								let lp = result.learning_progressions.find(x=>x.course_code==course_code)
								if (!lp) continue

								let teachers = []
								if (state.user_info.role === 'parent') {
									teachers = result.teachers[course_code]
								}

								commit('set', [state.sis_classes, 'PUSH', new window.User_Course({
									course_code: course_code,
									titles: [lp.title],
									teachers: [teachers]
								})])
							}
						} else {
							// sort sis_classes by title (added_my_courses will appear after these in the my courses list)
							result.classes.sort((a,b) => {
								if (a.titles[0] > b.titles[0]) return 1
								if (a.titles[0] < b.titles[0]) return -1
								return 0
							})
							for (let class_data of result.classes) {
								commit('set', [state.sis_classes, 'PUSH', new window.User_Course(class_data)])
							}
							// for teachers, create object with all students here
							if (state.user_info.role == 'staff') {
								commit('process_students_from_sis_classes')
							}
						}
					}

					// process incoming lp's
					for (let lp_data of result.learning_progressions) {
						// if lp_data includes an "active" flag, set cmap_specified to true
						if (lp_data.active) lp_data.cmap_specified = true

						let lp = state.all_courses.find(x=>x.course_code==lp_data.course_code)
						if (empty(lp)) {
							lp = new Learning_Progression(lp_data)
							commit('set', [state.all_courses, 'PUSH', lp])
						}
					}

					// if we have sis_classes that we didn't get LPs for, they are probably courses the teacher may be teaching that the agency hasn't coded LPs for; add "fake" LPs for these
					for (let sis_class of state.sis_classes) {
						let lp = state.all_courses.find(x=>x.course_code==sis_class.course_code)
						if (!lp) {
							lp = new Learning_Progression({
								course_code: sis_class.course_code,
								title: sis_class.title,
								collection_type: 'course',
							})
							commit('set', [state.all_courses, 'PUSH', lp])
						}
					}

					// store my_lessons, my_resources, and my_ca_mappings, then generate default collection for non-studentish users
					if (getters.signed_in) {
						for (let item_data of result.my_lessons) state.my_lessons.push(new Lesson(item_data))
						for (let item_data of result.my_resources) state.my_resources.push(new Resource(item_data))
						commit('set', ['my_ca_mappings', result.my_ca_mappings])

						console.log('result.my_ca_mappings ' + result.my_ca_mappings.length, result.my_ca_mappings)
						
						// if we're managing assignments, create activity records for incoming ca_mappings for resources in course collections
						state.my_activities_by_course = {}
						state.my_activities = []
						if (getters.manage_assignments) {
							if (result.my_ca_mappings) {
								for (let o of result.my_ca_mappings) {
									// skip default collection mappings
									if (o.collection_id.includes('default_collection')) continue
									// create an Activity and add it to my_activities/my_activities_by_course. Note that the user could have some my_ca_mappings that go with resources from repos, and that therefore aren't actually assignable activities; but it shouldn't hurt anything to process these in this way, and we might not know at this time which collections are actually courses.
									if (o.asset_type == 'resource') {
										o = new Activity(o)
										commit('add_to_my_activities', o)
									}
								}
							}

							// set my_activity_results if received
							let o = {}
							if (result.my_activity_results) for (let activity_id in result.my_activity_results) o[activity_id+''] = new Activity_Result(result.my_activity_results[activity_id])
							state.my_activity_results = o
						}
	
						if (!getters.studentish_role) {
							commit('generate_default_collection')
						}
					}

					// if teacher, process incoming coteacher info
					if (state.user_info.role == 'staff' && result.my_coteachers) {
						for (let coteacher of result.my_coteachers) {
							commit('set', [state.my_coteachers, 'PUSH', new Coteacher(coteacher)])
						}
						for (let course of result.my_coteaching_courses) {
							commit('set', [state.my_coteaching_courses, 'PUSH', course])
						}
					}

					commit('set', ['loaded_classes', true])

					resolve(result)
				});
			})
		},

		change_academic_year({state, commit, dispatch}, academic_year) {
			let payload = {
				user_id: state.user_info.user_id,
				academic_year: academic_year,
			}
			return new Promise((resolve, reject)=>{
				U.loading_start()
				U.ajax('change_academic_year', payload, result=>{
					state.user_info.academic_year = academic_year
					document.location.reload()

					U.loading_stop()

					resolve()
				});
			})
		},

		update_my_courses({state, commit, dispatch, getters}, payload) {
			// payload should include `course_code` and `action`, which should be `add` or `remove`
			return new Promise((resolve, reject)=>{
				// add or remove course to/from added_my_courses and removed_my_courses
				if (payload.action == 'add') {
					// but don't add to added_my_courses if the course is in sis_classes
					if (!state.sis_classes.find(x=>x.course_code==payload.course_code)) {
						commit('set', [state.added_my_courses, 'PUSH', payload.course_code])
					}
					commit('splice_from_array', [state.removed_my_courses, payload.course_code])
				} else {
					commit('set', [state.removed_my_courses, 'PUSH', payload.course_code])
					commit('splice_from_array', [state.added_my_courses, payload.course_code])
				}

				// if user isn't signed in, save to local storage
				if (!getters.signed_in) {
					// we only need to save added_my_courses to ls
					commit('lst_set', ['local_added_my_courses', state.added_my_courses])

				// else send to server
				} else {
					// add user_id to payload
					payload.user_id = state.user_info.user_id
					U.loading_start()
					U.ajax('update_my_courses', payload, result=>{
						U.loading_stop()
						if (result.status != 'ok') {
							vapp.$alert('Error updating My Courses.')
							vapp.ping()		// call ping to check if the session is expired
							reject()
							return
						}
						resolve()
					});
				}
			})
		},

		get_learning_progression({state, commit, dispatch, getters}, course_code) {
			let payload = {
				user_id: state.user_info.user_id,
				course_code: course_code,
				// get resources and resource_collections for all users, including parents/students, since they might have access to these resources if they're enrolled in the course
				// (LpUnitResourceCollectionTree will determine exactly what they have access to)
				retrieve_resources: 'yes',
				retrieve_resource_collections: 'yes',
			}
			// for teachers, retrieve additional info that students don't need
			if (state.user_info.role == 'staff' || state.user_info.role == 'admin') {
				payload.retrieve_case = 'yes'
				payload.retrieve_professional_development_resources = 'yes'
			}
			// TODO: take this out when we push new code to prod
			payload.no_lessons = 'true'

			return new Promise((resolve, reject)=>{
				let c = state.all_courses.find(o=>o.course_code == course_code)
				// if we already have the LP fully loaded, don't replace it
				if (c && c.fully_loaded) {
					resolve(true)
					return
				}
				// PW 4/18/2024: I believe the below check is redundant with above
				// // if we already have the LP in memory, don't replace it
				// if (c && c.units.length > 0) {
				// 	resolve(true)
				// 	return
				// }

				U.loading_start()
				U.ajax('get_learning_progression', payload, result=>{
					U.loading_stop()

					if (result.status == 'not_authenticated') {
						resolve('not_authenticated')
						return
					}

					if (result.status == 'no_admin_rights') {
						resolve('no_admin_rights')
						return
					}

					// if not found, create a "stub" lp for the course
					if (result.status == 'not_found') {
						resolve(false)
						return
					}

					if (result.status != 'ok') {
						console.log('Error retrieving curriculum map')
						vapp.ping()		// call ping to check if the session is expired
						reject()
						return
					}
					console.log('get_learning_progression: lp data for course_code ' + course_code, result)
					
					// if the lp already exists, don't do anything (it may have been loading via another service prior to this fn running)
					let i = state.all_courses.findIndex(o=>o.course_code == course_code)
					if (i == -1 || state.all_courses[i].units.length == 0 || state.all_courses[i].fully_loaded != true) {
						// set fully_loaded to true
						result.learning_progression.fully_loaded = true

						// if result.learning_progression includes an "active" flag, set cmap_specified to true
						if (result.learning_progression.active) result.learning_progression.cmap_specified = true

						// set fully_loaded to true
						result.learning_progression.fully_loaded = true

						let lp = new Learning_Progression(result.learning_progression)

						if (i == -1) {
							// push onto all_courses
							commit('set', [state.all_courses, 'PUSH', lp])
						} else {
							state.all_courses.splice(i, 1, lp)
						}
					}

					resolve(true)
				});
			})
		},

		save_learning_progression({state, commit, dispatch, getters}, lp) {
			// allow for a 'no_loader' param: this.$store.dispatch('save_learning_progression', {no_loader:true, lp:this.collection})
			let show_loader = true
			if (lp.no_loader === true) {
				show_loader = false
				lp = lp.lp
			}

			let lp_data = lp.copy_for_save()

			// if we're saving a new course-type collection, we need to set the year to the currently-selected year, if this instance does year-by-year lps
			if (state.site_config.limit_courses_to_academic_year == 'yes' && lp_data.collection_type == 'course' && lp_data.lp_id == 0) {
				lp_data.year = state.user_info.academic_year
			}

			// global database edit lock keys on last checkout timestamp, from server, to detect users editing a collection in different tabs/windows
			// - edit_access_control gets lock when user opens for editing
			// - on save, save_learning_progression call save service on back end to ensure lock still valid or available
			// - edit control service also checks locks user_id and expiry timestamp
			// should not see edit lock on new lp
			let last_checkout_timestamp = state.lp_edit_locked[lp_data.lp_id+'']
			if (lp_data.lp_id == 0) {
				last_checkout_timestamp = 'initial_save'
			} else if (typeof(last_checkout_timestamp) == 'undefined') {
				// something went wrong
				console.log("save fail: no edit lock checkout timestamp in save_learning_progression")
				vapp.collection_edit_lock_conflict_msg('Saving collection failed')
				return
			}
			
			let payload = {
				user_id: state.user_info.user_id,
				lp_data: JSON.stringify(lp_data),
				lp_checkout_timestamp: last_checkout_timestamp
			}

			return new Promise((resolve, reject)=>{
				if (show_loader) U.loading_start()
				U.ajax('save_learning_progression', payload, result=>{
					if (show_loader) U.loading_stop()
					if (result.status != 'ok') {
						console.log('Error in store.save_learning_progression')
						vapp.ping()		// call ping to check if the session is expired

						vapp.collection_edit_lock_conflict_msg(result, lp.lp_id, '1')
						
						reject()
						return
					}

					// update the updated_at value of the lp, as well as lp_id and course_code, which might have been created
					console.log('save_learning_progression result:', result)
					lp.updated_at = result.lp_updated_at
					lp.lp_id = result.lp_id
					lp.course_code = result.course_code

					// add the lp to the user's admin_rights if it isn't already there
					let rights_string = `lp.course.${lp.course_code}`
					if (!state.user_info.admin_rights.includes(rights_string)) {
						commit('add_to_array', [state.user_info.admin_rights, rights_string])
						U.ajax('update_admin_rights', {user_id: state.user_info.user_id, admin_rights: state.user_info.admin_rights }, result =>{
							if (result.status != 'ok') {
								vapp.$alert('A problem occurred while attempting to save the collection user rights')
							}
						});
					}

					// set returned lp_unit_ids, in case one or more units is new (or was a "999999X" value)
					for (let i = 0; i < lp.units.length; ++i) {
						lp.units[i].lp_unit_id = result.lp_unit_ids[i] * 1
					}

					// save updated lp_checkout_timestamp, in case this gets called again before the user "checks in" the LP
					commit('set', [state.lp_edit_locked, lp.lp_id+'', result.lp_checkout_timestamp])
					
					// trigger updates
					commit('trigger_course_update')
					resolve()
				});
			})
		},

		archive_learning_progression({state, commit, displatch, getters}, lp) {
			let payload = {
				user_id: state.user_info.user_id,
				course_code: lp.course_code
			}
			return new Promise((resolve, reject) => {
				U.loading_start()
				U.ajax('archive_learning_progression', payload, result => {
					U.loading_stop()
					if (result.status != 'ok') {
						console.log('Error archiving learning progression')
						vapp.ping() // call ping to check if the session is expired
						if (result.status != 'ok') {
							console.log('Error archiving learning progression')
							vapp.ping()		// call ping to check if the session is expired

							vapp.collection_edit_lock_conflict_msg(result, lp.lp_id, '2')

							reject()
							return
						}
					}
					// remove learning progression from state
					let index = state.all_courses.findIndex(o => o.course_code == lp.course_code)
					state.all_courses.splice(index, 1)
					resolve(result)
				})
			})
		},

		delete_learning_progression({state, commit, dispatch, getters}, lp) {
			let payload = {
				user_id: state.user_info.user_id,
				course_code: lp.course_code,
			}
			return new Promise((resolve, reject)=>{
				U.loading_start()
				U.ajax('delete_learning_progression', payload, result=>{
					U.loading_stop()
					if (result.status != 'ok') {
						console.log('Error deleting curriculum map')
						vapp.ping()		// call ping to check if the session is expired
						
						vapp.collection_edit_lock_conflict_msg(result, lp.lp_id, '3')

						reject()
						return
					}
					// splice out of all_courses array
					let index = state.all_courses.findIndex(o=>o.course_code == lp.course_code)
					state.all_courses.splice(index, 1)
					resolve(result)
				});
			})
		},

		duplicate_learning_progressions({state, commit, dispatch}, course_codes) {
			let payload = {
				user_id: state.user_info.user_id,
				course_codes: course_codes,
			}
			return new Promise((resolve, reject)=>{
				U.loading_start()
				U.ajax('duplicate_learning_progression', payload, result=>{
					U.loading_stop()
					if (result.status != 'ok') {
						console.log('Error duplicating curriculum map')
						vapp.ping()		// call ping to check if the session is expired
						reject()
						return
					}

					// reload all courses to get duplicates
					dispatch('get_all_courses')

					resolve()
				});
			})
		},

		share_learning_progression({state, commit, dispatch}, payload) {
			return new Promise((resolve, reject) => {
				U.loading_start()
				U.ajax('share_learning_progression', payload, result => {
					U.loading_stop()
					if (result.status != 'ok') {
						vapp.$alert(result.status); vapp.ping(); reject(); return;
					}
					resolve(result)
				})
			})
		},

		remove_user_from_shared_collection({state, commit, dispatch}, payload) {
			return new Promise((resolve, reject) => {
				U.loading_start()
				U.ajax('remove_user_from_shared_collection', payload, result => {
					U.loading_stop()
					if (result.status != 'ok') {
						vapp.$alert('Error in ajax call: ' + result.status); vapp.ping(); reject(); return;
					}
					resolve()
				})
			})
		},

		admin_change_collection_type({state, commit, displatch}, payload) {
			payload.user_id = state.user_info.user_id;
			return new Promise((resolve, reject) => {
				U.loading_start()
				U.ajax('admin_change_collection_type', payload, result => {
					U.loading_stop()
					if (result.status != 'ok') {
						vapp.$alert('Error in ajax call: ' + result.status); vapp.ping(); reject(); return;
					}
					const index = state.all_courses.findIndex(o => o.course_code == payload.course_code)
					if (index !== -1 && state.all_courses[index].collection_type !== payload.updated_collection_type) {
						commit('update_collection_type', { index, lp: result.lp });
					}
					resolve()
				})
			})
		},

		edit_user_shared_collection_rights({state, commit, dispatch}, payload) {
			return new Promise((resolve, reject) => {
				U.loading_start()
				U.ajax('edit_user_shared_collection_rights', payload, result => {
					U.loading_stop()
					if (result.status != 'ok') {
						vapp.$alert('Error in ajax call: ' + result.status); vapp.ping(); reject(); return;
					}
					resolve()
				})
			})
		},

		generate_subscription_code({state, commit, dispatch}, lp_id) {
			const payload = {
				user_id: state.user_info.user_id,
				lp_id: lp_id
			}
			return new Promise((resolve, reject) => {
				U.ajax('generate_subscription_code', payload, result => {
					if (result.status != 'ok') {
						vapp.$alert('Error in ajax call: ' + result.status); vapp.ping(); reject(); return;
					}
					// add subscription_code to the store record for the lp
					let collection = state.all_courses.find(x=>x.lp_id == lp_id)
					if (collection) {
						collection.subscription_code = result.subscription_code
						collection.updated_at = result.updated_at
					}
					resolve(result)
				})
			})
		},
		subscribe_to_collection({state, commit, dispatch}, sub_code) {
			const payload = {
				user_id: state.user_info.user_id,
				sub_code: sub_code
			}
			return new Promise((resolve, reject) => {
				U.ajax('subscribe_to_collection', payload, result => {
					if (result.status != 'ok') {
						vapp.$alert('Error in ajax call: ' + result.status); vapp.ping(); reject(); return;
					}
					resolve(result.lp)
				})
			})
		},
		unsubscribe_from_shared_collection({state, commit, dispatch}, course_code) {
			const payload = {
				user_id: state.user_info.user_id,
				course_code: course_code
			}
			return new Promise((resolve, reject) => {
				U.ajax('unsubscribe_from_shared_collection', payload, result => {
					if (result.status != 'ok') {
						vapp.$alert('Error in ajax call: ' + result.status); vapp.ping(); reject(); return;
					}
					// remove learning progression from state
					let index = state.all_courses.findIndex(o => o.course_code == course_code)
					state.all_courses.splice(index, 1)
					resolve()
				})
			})
		},

		// save the user's "default collection" items -- meaning the resources and lessons in the user's dummy default collection units
		save_default_collection_resources({state, commit, dispatch, getters}) {
			let payload = { user_id: state.user_info.user_id, resource_ids: [], lesson_ids: [] }
			let default_collection_id = getters.my_default_collection.default_collection_id_for_collection_asset_mapping()
			for (let unit of getters.my_default_collection.units) {
				// skip the placeholder resource if added
				for (let resource of unit.resources) if (!resource.placeholder) {
					// don't send resources that are mapped onto other collections
					if (state.my_ca_mappings.findIndex(x=>x.asset_id==resource.resource_id && x.asset_type=='resource' && x.collection_id!=default_collection_id) == -1) {
						payload.resource_ids.push(resource.resource_id)
						// add resource to my_resources if not already there
						if (!state.my_resources.find(x=>x.resource_id == resource.resource_id)) commit('set', [state.my_resources, 'PUSH', resource])
					}
				}
				for (let lesson of unit.lessons) {
					// don't send lessons that are mapped onto other collections
					if (state.my_ca_mappings.findIndex(x=>x.asset_id==lesson.lesson_id && x.asset_type=='lesson' && x.collection_id!=default_collection_id) == -1) {
						payload.lesson_ids.push(lesson.lesson_id)
						// add lesson to my_lessons if not already there
						if (!state.my_lessons.find(x=>x.lesson_id == lesson.lesson_id)) commit('set', [state.my_lessons, 'PUSH', lesson])
					}
				}
			}

			console.log('save_default_collection_resources', payload)

			return new Promise((resolve, reject)=>{
				U.loading_start()
				U.ajax('save_default_collection_resources', payload, result=>{
					U.loading_stop()
					if (result.status != 'ok') {
						vapp.$alert('Error in ajax call: ' + result.status); vapp.ping(); reject(); return;
					}

					// add added_ca_mapping_records to my_ca_mappings
					for (let ca_mapping of result.added_ca_mapping_records) {
						state.my_ca_mappings.push(ca_mapping)
					}

					// if we got any removed_ca_mapping_records, 
					for (let ca_mapping_id of result.removed_ca_mapping_records) {
						let ca_mapping_index = state.my_ca_mappings.findIndex(x=>x.ca_mapping_id == ca_mapping_id)
						if (ca_mapping_index > -1) {
							let cam = state.my_ca_mappings[ca_mapping_index]

							// remove from my_resources/my_lessons if necessary. check first to see if the item is mapped to another collection
							if (!state.my_ca_mappings.find(x => x!=cam && x.asset_id==cam.asset_id && x.asset_type==cam.asset_type)) {
								if (cam.asset_type == 'lesson') {
									let index = state.my_lessons.findIndex(x=>x.lesson_id == cam.asset_id)
									if (index > -1) {
										console.log('deleting', index, cam.asset_id)
										state.my_lessons.splice(index, 1)
									} else {
										console.log('not deleting', cam.asset_id)
									}
								} else {
									let index = state.my_resources.findIndex(x=>x.resource_id == cam.asset_id)
									if (index > -1) state.my_resources.splice(index, 1)
								}
							}

							// remove from my_ca_mappings
							state.my_ca_mappings.splice(ca_mapping_index, 1)
						}
					}

					// update default collection
					commit('generate_default_collection')

					resolve()
				})
			})
		},

		// save the user's items in a shadow unit
		synch_shadow_unit_assets({state, commit, dispatch, getters}, shadow_unit) {
			let payload = {
				user_id: state.user_info.user_id,
				lp_id: shadow_unit.shadows_lp_id,
				lp_unit_id: shadow_unit.shadows_lp_unit_id,
				resource_ids: [],
				lesson_ids: [],
			}
			for (let resource of shadow_unit.resources) {
				// skip placeholder resources if there
				if (resource.placeholder == true) {
					console.log('skipping placeholder')
					continue
				}
				payload.resource_ids.push(resource.resource_id)
				// add resource to my_resources if not already there
				if (!state.my_resources.find(x=>x.resource_id == resource.resource_id)) commit('set', [state.my_resources, 'PUSH', resource])
			}
			for (let lesson of shadow_unit.lessons) {
				payload.lesson_ids.push(lesson.lesson_id)
				// add lesson to my_lessons if not already there
				if (!state.my_lessons.find(x=>x.lesson_id == lesson.lesson_id)) commit('set', [state.my_lessons, 'PUSH', lesson])
			}
			console.log('shadow unit and payload', shadow_unit, payload)

			return new Promise((resolve, reject)=>{
				U.loading_start()
				U.ajax('synch_shadow_unit_assets', payload, result=>{
					U.loading_stop()
					if (result.status != 'ok') {
						console.log('Error in store.synch_shadow_unit_assets')
						vapp.ping()		// call ping to check if the session is expired
						reject()
						return
					}

					// add added_ca_mapping_records to my_ca_mappings
					for (let ca_mapping of result.added_ca_mapping_records) {
						state.my_ca_mappings.push(ca_mapping)
					}

					// if we got any removed_ca_mapping_records, 
					for (let ca_mapping_id of result.removed_ca_mapping_records) {
						let ca_mapping_index = state.my_ca_mappings.findIndex(x=>x.ca_mapping_id == ca_mapping_id)
						if (ca_mapping_index > -1) {
							let cam = state.my_ca_mappings[ca_mapping_index]

							// remove from my_resources/my_lessons if necessary. check first to see if the item is mapped to another collection
							if (!state.my_ca_mappings.find(x => x!=cam && x.asset_id==cam.asset_id && x.asset_type==cam.asset_type)) {
								if (cam.asset_type == 'lesson') {
									let index = state.my_lessons.findIndex(x=>x.lesson_id == cam.asset_id)
									if (index > -1) {
										console.log('deleting', index, cam.asset_id)
										state.my_lessons.splice(index, 1)
									} else {
										console.log('not deleting', cam.asset_id)
									}
								} else {
									let index = state.my_resources.findIndex(x=>x.resource_id == cam.asset_id)
									if (index > -1) state.my_resources.splice(index, 1)
								}
							}

							// remove from my_ca_mappings
							state.my_ca_mappings.splice(ca_mapping_index, 1)
						}
					}

					// update default collection
					commit('generate_default_collection')

					// caller should update shadow unit data structure
					resolve(result)
				});
			})
		},

		// global database edit lock keys on last checkout timestamp, from server, to detect users editing a collection in different tabs/windows
		// - edit_access_control gets lock when user opens for editing
		// - on save, save_learning_progression call save service on back end to ensure lock still valid or available
		// - edit control service also checks locks user_id and expiry timestamp
		edit_access_control({state, commit, dispatch, getters}, payload) {
			payload.user_id = state.user_info.user_id

			let last_checkout_timestamp = state.lp_edit_locked[payload.lp_id+'']
			last_checkout_timestamp = (typeof(last_checkout_timestamp) == 'undefined') ? 'initial_checkout' : last_checkout_timestamp
			payload.lp_checkout_timestamp = last_checkout_timestamp
			return new Promise((resolve, reject)=>{
				U.ajax('edit_access_control', payload, result=>{

					if (result.status == 'ok') {
						// vapp.$store.dispatch('edit_access_control', {lp_id: 803, action: 'check'})
						if (payload.action == 'check') console.log(result.edit_lock_data)
						// update the last_checkout_timestamp each edit request
						commit('set', [state.lp_edit_locked, payload.lp_id+'', result.lp_checkout_timestamp])
						resolve(result)

					} else if (result.status == 'session_conflict') {

						// this user has lock in another tab or window
						vapp.$confirm({
							text:'You already have this collection checked out in a different editing session (possibly in a different browser window). Would you like to edit here instead?',
							acceptText: "Edit Here"
						}).then(y => {
							// update edit_lock session_id and extend edit time
							payload.action = 'transfer_session'

							//payload.lp_checkout_timestamp = result.lp_checkout_timestamp
							// this updates session id in edit lock and checkout record, and informs user
							// if user then attempts to save, edit lock will be checked during save in new window
							U.ajax('edit_access_control', payload, transfer_result=>{
								// save the latest checkout time from updated db edit_lock
								commit('set', [state.lp_edit_locked, payload.lp_id+'', transfer_result.lp_checkout_timestamp])

								vapp.$inform('Collection “checked out” for editing.')
								resolve(transfer_result)
							})
						}).catch(n => {
							reject()
						}).finally(f=>{})
					} else {
						vapp.collection_edit_lock_conflict_msg(result, payload.lp_id, '4')
						reject()
					}
				});
			})
		},

		// check-in an LP from editing. payload should include only an lp_id; if it's 0, don't send this (in that case it was presumably a new lp
		edit_access_control_checkin({state, commit, dispatch}, payload) {
			// note that we don't do a promise here, because there will be no action based on the return value
			if (payload.lp_id == 0) {
				console.log('checkin request: lp_id is 0, so returning')
				return
			}

			payload.action = 'checkin'
			payload.user_id = state.user_info.user_id
			// checkin will clear edit lock in db
			payload.lp_checkout_timestamp = 'checking_in'
		
			U.ajax('edit_access_control', payload, (result)=>{
				if (result.status == 'ok') {
					console.log('checkin request: ' + result.status)
				} else {
					console.log('checkin request failed: ', result)
				}
			})
		},

		save_resource({state, commit, dispatch}, args) {
			let payload = args.resource.copy_for_save()

			payload.user_id = state.user_info.user_id

			// if (!empty(args.uploaded_file_data)) {
			// 	payload.uploaded_file_data = args.uploaded_file_data
			// }
			let override_options = null
			if (!empty(args.uploaded_file)) {
				// file upload
				if (payload.type == 'upload') {
					let fd = new FormData()
					fd.append('file', args.uploaded_file)
					for (let key in payload) {
						// for standards we have to stringify, because we can't append an object to the FormData (it would be converted to "[object Object],[object Object]")
						if (key == 'standards') fd.append(key, JSON.stringify(payload[key]))
						// we will never need lti_params for an uploaded file-type resource
						else if (key == 'lti_params') continue
						else fd.append(key, payload[key])
					}
					payload = fd
					override_options = {contentType: false, processData: false}
				} else {
					// html, entered in the resource editor directly
					payload.html = args.uploaded_file
				}
			}

			return new Promise((resolve, reject)=>{
				if (args.no_loader != true) U.loading_start()
				U.ajax('save_resource', payload, result=>{
					if (args.no_loader != true) U.loading_stop()
					if (result.status != 'ok') {
						console.log('Error updating resource')
						vapp.ping()		// call ping to check if the session is expired
						reject(result.status)
						return
					}

					// resolve with the resource data
					resolve(result.resource)
				}, override_options);
			})
		},

		delete_resource({state, commit, dispatch}, resource) {
			let payload = {
				user_id: state.user_info.user_id,
				resource_id: resource.resource_id,
			}
			return new Promise((resolve, reject)=>{
				U.loading_start()
				U.ajax('delete_resource', payload, result=>{
					U.loading_stop()
					if (result.status != 'ok') {
						console.log('Error deleting resource')
						vapp.ping()		// call ping to check if the session is expired
						reject()
						return
					}

					// caller is responsible for keeping track of the resource...
					resolve(resource.resource_id)
				});
			})
		},

		save_resource_collection({state, commit, dispatch}, payload) {
			// payload should contain 'resource_collection_title', 'resource_collection_json' and 'resources' (array)
			payload.user_id = state.user_info.user_id

			// stringify json and array
			payload.resource_collection_json = JSON.stringify(payload.resource_collection_json)
			payload.resources = JSON.stringify(payload.resources)

			return new Promise((resolve, reject)=>{
				U.loading_start()
				U.ajax('save_resource_collection', payload, result=>{
					U.loading_stop()
					if (result.status != 'ok') {
						console.log('Error saving resource collection')
						vapp.ping()		// call ping to check if the session is expired
						reject()
						return
					}

					// resolve with resource_collection_resource_id
					resolve(result.resource_collection_resource_id)
				})
			})
		},

		save_resource_completion({state, commit, dispatch}, payload) {
			// payload should contain 'resource_id' and 'todo_status', which can be 0 (not complete), 100 (complete), or 5-95 (partially complete video); add user_id
			payload.user_id = state.user_info.user_id

			return new Promise((resolve, reject)=>{
				U.ajax('save_resource_completion', payload, result=>{
					if (result.status != 'ok') {
						console.log('Error saving resource completion')
						vapp.ping()		// call ping to check if the session is expired
						reject()
						return
					}

					// completion timestamp should come back from service if set; otherwise we'll get back the todo_status we sent in; either way, save in store
					commit('set', [state.user_info.todo_status, payload.resource_id, result.todo_status])

					// note that this may be called without a then()
					resolve()
				})
			})
		},

		save_todo_user_group({state, commit, dispatch}, payload) {
			// payload should contain 'todo_user_id' and 'todo_user_group' (a uuid); add the signed-in user's user_id
			payload.user_id = state.user_info.user_id

			// if todo_user_group is empty, send '*CLEAR*' to clear it out
			if (payload.todo_user_group == '') payload.todo_user_group = '*CLEAR*'

			return new Promise((resolve, reject)=>{
				U.loading_start()
				U.ajax('save_todo_user_group', payload, result=>{
					U.loading_stop()
					if (result.status != 'ok') {
						console.log('Error in save_todo_user_group')
						vapp.ping()		// call ping to check if the session is expired
						reject()
						return
					}

					// if todo_user_id is the signed-in user, set todo_user_group for state.user_info
					if (payload.todo_user_id == state.user_info.user_id) {
						if (payload.todo_user_group == '*CLEAR*') {
							commit('set', [state.user_info, 'todo_user_group', ''])
						} else {
							commit('set', [state.user_info, 'todo_user_group', payload.todo_user_group])
						}
					}

					// note that this may be called without a then()
					resolve()
				})
			})
		},

		get_todo_report_data({state, commit, dispatch}, payload) {
			// payload should contain 'todo_user_group' (a uuid, or 'all' for all groups); add user_id
			payload.user_id = state.user_info.user_id

			return new Promise((resolve, reject)=>{
				U.loading_start()
				U.ajax('get_todo_report_data', payload, result=>{
					U.loading_stop()
					if (result.status != 'ok') {
						console.log('Error in get_todo_report_data')
						vapp.ping()		// call ping to check if the session is expired
						reject()
						return
					}

					commit('set', [state.todo_report_group_data, payload.todo_user_group, result.todo_report_data])

					// note that this may be called without a then()
					resolve()
				})
			})
		},

		get_all_courses({state, commit, dispatch}, flag) {
			if (!flag) flag = ''
			let payload = {
				user_id: state.user_info.user_id,
			}
			return new Promise((resolve, reject)=>{
				if (flag.includes('initial')) {
					if (!empty(state.all_courses_initial)) {
						if (state.all_courses_initial.complete) {
							resolve()
						} else {
							state.all_courses_initial.callbacks.push(resolve)
						}
						return
					} else {
						state.all_courses_initial = { complete: false, callbacks: [resolve] }
					}
				}

				if (!flag.includes('no_loader')) U.loading_start()
				U.ajax('get_all_courses', payload, result=>{
					if (!flag.includes('no_loader')) U.loading_stop()
					if (result.status != 'ok') {
						console.log('Error retrieving all courses')
						vapp.ping()		// call ping to check if the session is expired
						reject()
						return
					}

					console.log('get_all_courses', result)

					for (let course of result.all_courses) {
						let lp = new Learning_Progression(course)
						// if we already have the course in all_courses...
						let index = state.all_courses.findIndex(o=>o.course_code == course.course_code)
						if (index > -1) {
							let existing_course = state.all_courses[index]
							// then only overwrite what we already have if it wasn't fully loaded
							if (!existing_course.fully_loaded) {
								state.all_courses[index] = lp
							} else {
								// otherwise fill in the value for cmap_specified, which may not have come in initially
								commit('set', [existing_course, 'cmap_specified', lp.cmap_specified])
							}
						} else {
							// else push onto all_courses
							commit('set', [state.all_courses, 'PUSH', lp])
						}
					}
					commit('set', ['all_courses_loaded', true])

					if (state.all_courses_initial && !state.all_courses_initial.complete) {
						state.all_courses_initial.complete = true
						for (let cb of state.all_courses_initial.callbacks) cb()

					} else {
						resolve()
					}
				});
			})
		},

		save_activity({state, commit, dispatch}, payload) {
			// payload must include:
			// activity_data, which should have been run through Activity.copy_for_save and will be stringified here
			// optional: assignees, which should have been run through Assignee.copy_for_save and will be stringified here if provided
			// optional: suppress_loader (boolean)
			// TODO: deal with activity_class ('teacher' or 'template')
			payload.activity_data = JSON.stringify(payload.activity_data)
			if (payload.assignees) payload.assignees = JSON.stringify(payload.assignees)
			payload.user_id = state.user_info.user_id
			return new Promise((resolve, reject)=>{
				let suppress_loader = payload.suppress_loader
				if (suppress_loader) delete payload.suppress_loader
				else U.loading_start(payload.loader_msg)	// loader_msg will be set if we're updating IC gradebook settings
				delete payload.loader_msg

				U.ajax('save_activity', payload, result=>{
					if (!suppress_loader) U.loading_stop()
					if (result.status != 'ok') {
						console.error('Error in save_activity call'); vapp.ping(); reject(result); return;
					}

					if (result.activity) {
						// add/update the "raw" ca_mapping data in my_ca_mappings
						let ca_mapping_index = state.my_ca_mappings.findIndex(x=>x.ca_mapping_id == result.activity.ca_mapping_id)
						if (ca_mapping_index > -1) {
							state.my_ca_mappings.push(result.activity)
						} else {
							state.my_ca_mappings.splice(ca_mapping_index, 1, result.activity)
						}
					}

					// note that it's up to the caller to save changes to the activity to the store
					resolve(result)
				});
			})
		},

		save_lesson({state, commit, dispatch}, payload) {
			// payload must include:
			// lesson_class ('master', 'teacher' or 'template')
			// lesson_data, which should have been run through Activity.copy_for_save and will be stringified here
			// optional: suppress_loader (boolean)
			payload.lesson_data = JSON.stringify(payload.lesson_data)
			payload.user_id = state.user_info.user_id
			return new Promise((resolve, reject)=>{
				let suppress_loader = payload.suppress_loader
				if (suppress_loader) delete payload.suppress_loader
				else U.loading_start()

				U.ajax('save_lesson', payload, result=>{
					if (!suppress_loader) U.loading_stop()
					if (result.status != 'ok') {
						console.log('Error in save_lesson call'); vapp.ping(); reject(); return;
					}

					// note that it's up to the caller to save changes to store
					resolve(result)
				});
			})
		},

		save_my_activity_result({state, commit, dispatch}, activity_id) {
			return new Promise((resolve, reject)=>{
				let ar = state.my_activity_results[activity_id]
				if (empty(ar)) {	// shouldn't happen
					console.log('no_my_activity_result')
					reject()
					return
				}

				let payload = {
					user_id: state.user_info.user_id,
					activity_result_data: JSON.stringify(ar.copy_for_save())
				}

				// if this student's results need to go into the IC gradebook, add relevant params
				let activity = state.my_activities.find(x=>x.activity_id == activity_id)
				if (activity) activity.add_gradebook_data_to_payload(payload)
		
				// U.loading_start()
				U.ajax('save_activity_result_by_student', payload, result=>{
					// U.loading_stop()
					if (result.status != 'ok') {
						console.log('Error in save_activity_result_by_student call'); vapp.ping(); reject(); return;
					}

					// replace result record in my_activity_results
					commit('set', [state.my_activity_results, activity_id+'', new Activity_Result(result.activity_result)])
					resolve()
				});
			})
		},
	
		get_messages({state, commit, dispatch}, payload) {
			// don't try to get messages if we don't have a sis_user_sourcedId (unless we happen to be viewing as parent)
			if (state.user_info.sis_user_sourcedId == '' && state.user_info.role !== 'parent') return

			payload.user_id = state.user_info.user_id
			payload.sis_user_sourcedId = state.user_info.sis_user_sourcedId
			payload.role = state.user_info.role

			// add a timestamp of when the last message load happened so we can only load new messages; if this value is 0 (the initial value in state), all messages will be retrieved
			payload.message_load_timestamp = state.message_load_timestamp
			return new Promise((resolve, reject)=>{
				// only show loading indicator for the first time we're doing this
				if (payload.message_load_timestamp == 0) U.loading_start()
				U.ajax('get_messages', payload, result=>{
					if (payload.message_load_timestamp == 0) U.loading_stop()
					if (result.status != 'ok') {
						console.log('Error retrieving messages for course')
						vapp.ping()		// call ping to check if the session is expired
						reject()
						return
					}

					let filtered_messages = result?.messages ?? []
					if (state.user_info.role === 'student') {
						filtered_messages = filtered_messages?.filter(message => message.send_to !== 'Guardian')
					}
					if (state.user_info.role === 'parent') {
						filtered_messages = filtered_messages?.filter(message => message.send_to !== 'Student')
					}

					// store incoming data...
					for (let message_data of filtered_messages) {
						// console.log(`message_data ${JSON.stringify(message_data)}`)
						let message = new window.Message(message_data)
						commit('replace_in_array', [state.messages, 'message_id', message.message_id, message])
					}

					// store incoming message_load_timestamp
					state.message_load_timestamp = result.message_load_timestamp
				})
			})
		},

		save_message({ state, commit, dispatch }, message) {
			console.log('saving message', message)
			let payload = {
				user_id: state.user_info.user_id,
				message_id: message.message_id,
				author_user_id: state.user_info.user_id,
				course_code: message.course_code,
				parent_message_id: message.parent_message_id,
				message_level: message.message_level,
				recipients: JSON.stringify(message.recipients),
				subject: message.subject,
				send_to: message.send_to,
				send_at: message.send_at,
				first_name: state.user_info.first_name,
				last_name: state.user_info.last_name,
				author_sourcedId: state.user_info.sis_user_sourcedId,
				activity_id: message.activity_id,
			}
			if (!empty(message.body)) payload.body = message.body
			return new Promise((resolve, reject) => {
				U.loading_start()
				console.log('promising')
				U.ajax('save_message', payload, result => {
					console.log('promising done')
					U.loading_stop()
					if (result.status != 'ok') {
						vapp.ping() // call ping to check if the session is expired
						console.log('Error saving message')
						reject()
						return
					}
					// update message_id, in case this was a new message being saved
					message.message_id = result.message_id
					message.created_at = date.parse(result.created_at, 'YYYY-MM-DD HH:mm:ss').getTime() / 1000
					message.is_read = 1 // author has read the message by default
					resolve()
				})
			})
		},

		delete_message({state, commit, dispatch}, message) {
			return new Promise((resolve, reject)=>{
				let index = state.messages.findIndex(o=>o == message)
				state.messages.splice(index, 1)
				resolve()
				return
			})
		},

		archive_message({state, commit, dispatch}, message) {
			return new Promise((resolve, reject)=> {
				U.ajax('archive_message', {user_id: state.user_info.user_id, message_id: message.message_id}, result=>{
					if (result.status != 'ok') {
						vapp.ping()		// call ping to check if the session is expired
						console.log('Error archiving message')
						reject()
						return
					}
					let index = state.messages.findIndex(o=>o.message_id == message.message_id)
					state.messages.splice(index, 1)
					resolve()
				});
			})
		},

		mark_message_as_read({state, commit, dispatch}, message) {
			const payload = {
				user_id: state.user_info.user_id,
				user_sourcedId: state.user_info.sis_user_sourcedId,
				message_id: message.message_id,
			}
			let index = state.messages.findIndex(o=>o.message_id == message.message_id)
			state.messages[index].is_read = 1
			return new Promise((resolve, reject)=>{
				U.ajax('mark_message_as_read', payload, result=>{
					if (result.status != 'ok') {
						vapp.ping()		// call ping to check if the session is expired
						console.log('Error marking message as read')
						reject()
						return
					}
					resolve()
				})
			})
		},

		save_message_preferences({state, commit, dispatch}, prefs) {
			// console.log('saving message prefs', JSON.stringify(prefs))
			return new Promise((resolve, reject)=>{
				U.loading_start()
				U.ajax('save_message_preferences', prefs, result=>{
					U.loading_stop()
					if (result.status != 'ok') {
						vapp.ping()		// call ping to check if the session is expired
						console.log('Error saving message')
						reject()
						return
					}
					commit('set', [state.user_info, 'message_preferences', result.updated_prefs])
					resolve()
				});
			})
		},

		lti_launch({state, commit, dispatch}, payload) {
			// To do an LTI launch, we call a service to get the LTI form html along with the javascript to auto-submit the form,
			// then we open a new window and write the html/js
			payload.user_id = state.user_info.user_id
			U.loading_start()
			U.ajax('get_lti_1_launch_form', payload, result=>{
				U.loading_stop()
				if (result.status != 'ok') {
					vapp.ping()		// call ping to check if the session is expired
					vapp.$alert('An error occurred when attempting to launch the resource.')
					return
				}

				// see https://developer.mozilla.org/en-US/docs/Web/API/Window/open
				let w = window.open()
				w.document.write(result.lti_form)
			});
		},

		get_resource_record({state, commit, dispatch}, payload) {
			// payload should include the resource_id being queried; this fn is mainly (or possibly exclusively) used for lti launches
			// if payload includes "get_lti_form:'yes'", we will retrieve the form to do the launch

			// we have to send the user's *email address* for this service
			payload.email = state.user_info.email

			// don't show loading indicator here, as we call the service on hover for resources
			// U.loading_start()
			return new Promise((resolve, reject)=>{
				U.ajax('get_resource_record', payload, result=>{
					// U.loading_stop()
					if (result.status != 'ok') {
						vapp.ping()		// call ping to check if the session is expired
						vapp.$alert('An error occurred when attempting to retrieve data about the resource.')
						reject()
						return
					}

					resolve(result)
				});
			})
		},

		// log to resource_usage table
		log_resource_usage({state, commit, dispatch}, payload) {
			// state.lp_showing is set in App.vue on the basis of the route
			payload.course_code = state.lp_showing
			return new Promise((resolve, reject)=>{
				U.ajax('log_resource_usage', payload, result=>{
					// don't block other activity for logging errors
				});
			})
		},

		get_lsdoc_list({state, commit, dispatch}) {
			let payload = { user_id: state.user_info.user_id }

			// add subject-area framework identifiers to payload, since we only need to retrieve data for them
			let arr = []
			for (let s in state.subjects) {
				arr.push(state.subjects[s].framework_identifier)
				// allow for alt_frameworks
				let alt_frameworks = state.subjects[s].alt_frameworks
				if (alt_frameworks) for (let a in alt_frameworks) {
					arr.push(alt_frameworks[a])
				}
			}
			payload.framework_identifier_filter = JSON.stringify(arr)

			// set frameworks_loading to true BEFORE framework_records have been loaded, so that other courses' pages won't also load the framework list
			state.frameworks_loading = true

			U.loading_start()
			return new Promise((resolve, reject)=>{
				U.ajax('get_framework_list', payload, result=>{
					U.loading_stop()

					if (result.status != 'ok') {
						vapp.ping()		// call ping to check if the session is expired
						vapp.$alert('An error occurred when attempting to retrieve the standards frameworks list.')
						reject()
						return
					}

					let arr = []
					for (let record of result.records) {
						// console.log(record.framework_identifier)
						let json = JSON.parse(record.json)
						let doc = new CFDocument(json.case_document_json)

						// for now, skip records that don't match frameworks in our subjects array
						let found = false
						for (let s in state.subjects) {
							if (state.subjects[s].framework_identifier == doc.identifier) {
								found = true
								break
							}
							// allow for alt_frameworks
							let alt_frameworks = state.subjects[s].alt_frameworks
							if (alt_frameworks) for (let a in alt_frameworks) {
								if (alt_frameworks[a] == doc.identifier) {
									found = true
									console.warn('found alt_framework')
									break
								}
							}
						}

						if (!found) continue

						delete(json.case_document_json)
						let framework_record = U.create_framework_record(doc.identifier, {CFDocument: doc}, json, false)
						arr.push(framework_record)
					}

					// sort by title, keeping fine arts and CTAE at the end
					arr = arr.sort((a,b) => {
						if (a.json.CFDocument.title.indexOf('CTAE') > -1 && b.json.CFDocument.title.indexOf('CTAE') == -1) return 1
						if (b.json.CFDocument.title.indexOf('CTAE') > -1 && a.json.CFDocument.title.indexOf('CTAE') == -1) return -1

						if (a.json.CFDocument.title.indexOf('Fine Arts') > -1 && b.json.CFDocument.title.indexOf('Fine Arts') == -1) return 1
						if (b.json.CFDocument.title.indexOf('Fine Arts') > -1 && a.json.CFDocument.title.indexOf('Fine Arts') == -1) return -1

						if (a.json.CFDocument.title.toLowerCase() < b.json.CFDocument.title.toLowerCase()) return -1
						if (b.json.CFDocument.title.toLowerCase() < a.json.CFDocument.title.toLowerCase()) return 1
						return 0
					})

					state.framework_records = arr

					// now set frameworks_loading to false and frameworks_loaded to true
					state.frameworks_loading = false
					state.frameworks_loaded = true

					resolve()
				})
			})
		},

		get_lsdoc({state, commit, dispatch}, lsdoc_identifier) {
			return new Promise((resolve, reject)=>{
				let filepath = sr('frameworks/$1.json', lsdoc_identifier)
				// TODO: get_json_file will throw an error if the json is malformed; need to test what happens if that happens.
				U.get_json_file(filepath, result=>{
					if (typeof(result) == 'string') {
						console.log('Error in get_lsdoc', result)
						reject(result)
						return
					}

					// the result should hold the CASE JSON for the framework, already JSON.parse'd
					// console.log('CASE JSON:', result)

					// the framework_record should already exist when this is called,
					let fr = state.framework_records.find(x=>x.lsdoc_identifier==lsdoc_identifier)
					if (!fr) {
						vapp.$alert('Framework record not found: the value entered for “CASE framework identifier” in the course editor may not be valid.')
						reject()
					} else {
						// so all we have to do is store the json and set framework_json_loaded to true, which framework_record_load_json does
						U.framework_record_load_json(fr, result)
					}

					resolve()
					// note that we don't need to load the exemplar frameworks in Inspire
				})
			})
		},

		get_lesson_masters({state, commit, dispatch}, force) {
			return new Promise((resolve, reject)=>{
				// unless force is true, only load masters if we haven't already done so
				if (state.loading_lesson_masters || (force !== true && state.lesson_masters.length > 0)) {
					resolve()
					return
				}

				state.loading_lesson_masters = true

				U.loading_start()
				U.ajax('get_lesson_masters', {user_id: state.user_info.user_id}, result=>{
					U.loading_stop()
					state.loading_lesson_masters = false
					state.lesson_masters = []

					if (result.status != 'ok') {
						vapp.ping()		// call ping to check if the session is expired
						vapp.$alert('An error occurred when attempting to retrieve the lesson masters (' + result.status + ').')
						reject()
						return
					}

					for (let lm of result.masters) {
						// legacy: masters originally had lc_content fields for each component; convert to lc_default_content
						for (let lc of lm.lesson_plan) if (lc.lc_content&&typeof(lc.lc_content)=='string') { lc.lc_default_content = lc.lc_content }
						state.lesson_masters.push(new Lesson(lm))
					}

					// sort so that newer masters are earlier in the list
					state.lesson_masters.sort((a,b)=>b.lesson_id-a.lesson_id)

					if (result.default_lesson_master_id != 0) {
						state.default_lesson_master = state.lesson_masters.find(x=>x.lesson_id == result.default_lesson_master_id)
						if (empty(state.default_lesson_master)) {
							// this shouldn't happen
							vapp.$alert('Couldn’t identify default lesson master (' + result.default_lesson_master_id + ')')
						}
					}

					resolve()
				});
			})
		},

		load_lesson({state, commit, dispatch}, lesson) {
			// fully-load a lesson that previously just had its lesson_id and lesson_title loaded
			U.loading_start()
			return new Promise((resolve, reject)=>{
				U.ajax('get_lesson', {user_id: state.user_info.user_id, lesson_id: lesson.lesson_id, lp_flag: 'no_load'}, result=>{
					U.loading_stop()
					if (result.status == 'not_found') {
						vapp.$alert('The specified lesson (L' + lesson.lesson_id + ') does not exist.')
						reject()
						return
					} else if (result.status != 'ok') {
						console.log('Error in ajax call'); vapp.ping(); reject(); return;
					}

					let temp_lesson = new Lesson(result.lesson)
					for (let prop in temp_lesson) {
						commit('set', [lesson, prop, temp_lesson[prop]])
					}

					resolve(lesson)
				})
			})
		},

		// server side pdf writer if adding resource pdfs to lesson
		save_to_pdf({state, commit, dispatch}, payload) {
			payload.user_id = state.user_info.user_id

			U.loading_start()
			return new Promise((resolve, reject)=>{
				U.ajax('save_to_pdf', payload, result=>{
					U.loading_stop()
					if (result.status != 'ok') {
						vapp.ping()		// call ping to check if the session is expired
						vapp.$alert('An error occurred when attempting to save this page as pdf.')
						reject()
						return
					}
					resolve(result)
				});
			})
		},

		get_home_page_content({state, commit, dispatch}, home_page_variant) {
			return new Promise((resolve, reject)=>{
				// if we already have home_page_content, immediately resolve
				if (state.home_page_content[home_page_variant]) resolve()
				U.ajax('get_home_content', {home_page_variant: home_page_variant}, result=>{
					if (result.status != 'ok') {
						vapp.ping()		// call ping to check if the session is expired
						vapp.$alert('An error occurred when attempting to retrieve the home page content.')
						return
					}
					commit('set', [state.home_page_content, home_page_variant, result.home_page_content])
					resolve()
				})
			})
		},

		get_user_records({state, commit}, payload) {
			// get user records from the DB
			// payload can include a `user_ids` array or an `emails` array
			payload.user_id = state.user_info.user_id
			return new Promise((resolve, reject)=>{
				U.ajax('get_user_records', payload, result=>{
					if (result.status != 'ok') {
						vapp.ping()		// call ping to check if the session is expired
						vapp.$alert('An error occurred when attempting to retrieve user records.')
						return
					}
					let arr = []
					for (let user_data of result.users) {
						let u = new User_Info(user_data)
						u.email = u.email.toLowerCase() // make sure all emails are lower case
						arr.push(u)
						let i = state.user_records.findIndex(x=>x.user_id==user_data.user_id)
						if (i == -1) state.user_records.push(u)
						else state.user_records.splice(i, 1, u)
					}
					resolve(arr)
				})
			})
		},

		admin_get_term_settings({state, commit, dispatch}) {
			const payload = {
				user_id: state.user_info.user_id
			}
			return new Promise((resolve, reject)=>{
				U.ajax('admin_get_term_settings', payload, result=>{
					if (result.status != 'ok') {
						vapp.$alert('An error occurred when attempting to get the term settings.')
						reject()
						return
					}
					if (state.user_info.role == 'admin') {
						commit('set', ['term_settings', result.term_settings])
					}
				})
				resolve()
			})
		},

		admin_update_term_settings({state, commit, dispatch}, payload) {
			payload.user_id = state.user_info.user_id
			return new Promise((resolve, reject)=>{
				U.ajax('admin_update_term_settings', payload, result=>{
					if (result.status != 'ok') {
						vapp.$alert('An error occurred when attempting to update the term settings.')
						reject()
						return
					}
				})
				resolve()
			})
		},

		check_out_lesson_for_editing({state, commit, dispatch}, payload) {
			return new Promise((resolve, reject) => {
				return U.ajax('check_out_lesson_for_editing', payload, res => {
					if(res.status === 'lock_conflict') {
						vapp.$alert(`This lesson is currently checked out for editing by ${res.email}. Please try again later (the edit lock will expire in 15 minutes or less).`)
						reject('checkout rejected due to edit conflict')
					} else resolve(res)
				})
			})
		},

		// this.$store.dispatch('update_sis_data')
		update_sis_data({state, commit, dispatch}) {
			let payload = {
				user_id: state.user_info.user_id,
			}

			U.ajax('clear_sis_data', payload, result=>{
				// this will clear the user's sis data and prepare things so that when we reload, initialize_app will refresh the sis data
				document.location.reload()
			});
		},

		save_coteacher({state, commit, dispatch}, payload) {
			return new Promise((resolve, reject)=>{
				U.loading_start()
				U.ajax('save_coteacher', payload, result=>{
					U.loading_stop()
					if (result.status != 'ok') {
						vapp.ping()		// call ping to check if the session is expired
						console.log('Error saving coteacher')
						reject()
						return
					}
					let ct = new window.Coteacher(result.coteacher)
					if (state.my_coteachers.findIndex(o=>o.coteacher_id == ct.coteacher_id) == -1) {
						// we are adding a new coteacher
						commit('set', [state.my_coteachers, 'PUSH', ct])
					} else {
						// we are edting an existing coteacher
						commit('replace_in_array', [state.my_coteachers, 'coteacher_id', ct.coteacher_id, ct])
					}
					resolve()
				})
			})
		},

		delete_coteacher({state, commit, dispatch}, coteacher) {
			let payload = {
				user_id: state.user_info.user_id,
				coteacher_id: coteacher.coteacher_id,
			}
			return new Promise((resolve, reject)=>{
				U.loading_start()
				U.ajax('delete_coteacher', payload, result=>{
					U.loading_stop()
					if (result.status != 'ok') {
						vapp.$alert('An error occurred when attempting to delete the coteacher.')
						vapp.ping()		// call ping to check if the session is expired
						reject()
						return
					}
					// splice out of coteachers array
					let index = state.my_coteachers.findIndex(o=>o == coteacher)
					state.my_coteachers.splice(index, 1)
					resolve()
				})
			})
		},

		// this.$store.dispatch('sign_out')
		sign_out({state, commit, dispatch}) {
			// clear the session_id from localstorage, since it's no longer valid
			U.local_storage_clear('gaconnects_session_id')

			// also clear simulated_user and simulated_role from lst
			commit('lst_set', ['simulated_user', ''])
			commit('lst_set', ['simulated_role', ''])

			U.loading_start()
			// if we're using OIDC, signout of the oidc system first
			if (state.site_config.login_method == 'oidc') {
				let sw = window.open('https://gaconnects.gadoe.org/Account/signout')
				setTimeout(x=>{
					// close the signout window
					sw.close()

					U.ajax('sign_out', { user_id: state.user_info.user_id }, result=>{
						console.log('signed out')
						// regardless of result, reload the page, so we're in non-signed-in mode
						document.location.reload()
					});
				}, 10)
			} else {
				U.ajax('sign_out', { user_id: state.user_info.user_id }, result=>{
					console.log('signed out')
					// regardless of result, reload the page, so we're in non-signed-in mode
					document.location.reload()
				});
			}
		},
	}
})
